import { apiClient } from "..";
import { getActivityUrl } from "../apiConfig";
import { assertError } from "../apiHelper";
import postFieldsApi from "../fields/postFieldsApi";

export const getActivity = async (id) => {
	let requestData = getActivityRequest(id);
	return await postActivityManagement(requestData);
};

export const getActivitiesData = async (
	entityId,
	isDone,
	source,
	activityTypeIds,
	pageParams
) => {
	let requestData = getActivitiesRequest(
		entityId,
		isDone,
		source,
		activityTypeIds,
		pageParams
	);
	return await postActivityManagement(requestData);
};

export const getActivitiesCalendarViewData = async (
	weekCount,
	isWeekEnd,
	criteria
) => {
	let requestData = getActivitiesCalendarViewRequest(
		weekCount,
		isWeekEnd,
		criteria
	);
	return await postActivityManagement(requestData);
};

export const createActivity = async (data) => {
	let requestData = getCreateActivityRequest(data);
	return await postActivityManagement(requestData);
};

export const updateActivity = async (data) => {
	let requestData = getUpdateActivityRequest(data);
	return await postActivityManagement(requestData);
};

export const getDealOverviewData = async (id) => {
	let requestData = getDealOverviewRequest(id);
	return await postActivityManagement(requestData);
};

export const partialUpdateActivity = async (activityUpdatedRequestData) => {
	let requestData = getPartialUpdateRequest(activityUpdatedRequestData);
	return await postActivityManagement(requestData);
};

export const deleteActivity = async (id) => {
	let requestData = getDeleteActivityRequest(id);
	return await postActivityManagement(requestData);
};

export const getSummaryData = async (id) => {
	let requestData = getSummaryRequest(id);
	return await postActivityManagement(requestData);
};

export const deleteMultipleActivities = async (activitiesToDelete) => {
	let requestData = getDeleteMultipleActivitiesRequest(activitiesToDelete);
	return await postActivityManagement(requestData);
};

export const partialBulkUpdateActivity = async (activityUpdatedRequestData) => {
	let requestData = getPartialBulkUpdateRequest(activityUpdatedRequestData);
	return await postActivityManagement(requestData);
};

export const getDueDateList = async (sourceId) => {
	let requestData = getDueDateListRequestData(sourceId);
	return await postFieldsApi(requestData);
};

export const getUserActivities = async (userId, isDone, pageParam) => {
	let requestData = getUserActivitiesRequest(userId, isDone, pageParam);
	return await postActivityManagement(requestData);
};

export const getCollaboratorList = async (activityId) => {
	let requestData = getCollaboratorListRequesData(activityId);
	return await postActivityManagement(requestData);
};

const getActivityRequest = (id) => {
	return {
		type: "get",
		data: {
			id: id,
		},
	};
};

const getActivitiesRequest = (
	entityId,
	isDone,
	source,
	activityTypeIds,
	pageParams
) => {
	return {
		type: "getActivities",
		data: {
			entityId: entityId,
			isDone: isDone,
			source: source,
			activityTypeIds: activityTypeIds,
			...pageParams,
		},
	};
};

const getActivitiesCalendarViewRequest = (weekCount, isWeekEnd, criteria) => {
	if (Array.isArray(criteria)) {
		return {
			type: "calendarView",
			data: {
				filter: {
					weekCount: weekCount,
					isWeekEnd: isWeekEnd,
				},
				criteria: criteria,
			},
		};
	}
	return {
		type: "calendarView",
		data: {
			filter: {
				weekCount: weekCount,
				isWeekEnd: isWeekEnd,
			},
		},
	};
};

const getCreateActivityRequest = (data) => {
	return {
		type: "create",
		data: data,
	};
};

const getUpdateActivityRequest = (data) => {
	return {
		type: "update",
		data: data,
	};
};

const getDealOverviewRequest = (id) => {
	return {
		type: "dealOverview",
		data: {
			id: id,
		},
	};
};

const getPartialUpdateRequest = (activityUpdatedRequestData) => {
	return {
		type: "pUpdate",
		data: activityUpdatedRequestData,
	};
};

const getDeleteActivityRequest = (id) => {
	return {
		type: "delete",
		data: {
			id: id,
		},
	};
};

const getSummaryRequest = (id) => {
	return {
		type: "summary",
		data: {
			id: id,
		},
	};
};

const getDeleteMultipleActivitiesRequest = (activitiesToDelete) => {
	return {
		type: "bDelete",
		data: {
			ids: activitiesToDelete,
		},
	};
};

const getPartialBulkUpdateRequest = (activityUpdatedRequestData) => {
	return {
		type: "pBulkUpdate",
		data: {
			list: activityUpdatedRequestData,
		},
	};
};

const getDueDateListRequestData = (sourceId) => {
	return {
		type: "dropdownData",
		data: {
			sourceId: sourceId,
			name: "dateIntervalList",
			includeDefaults: false,
		},
	};
};

const getUserActivitiesRequest = (userId, isDone, pageParam) => {
	return {
		type: "getUserActivities",
		data: {
			userId: userId,
			isDone: isDone,
			...pageParam,
		},
	};
};

const getCollaboratorListRequesData = (activityId) => {
	return {
		type: "getCollaborator",
		data: {
			id: activityId,
		},
	};
};

const postActivityManagement = async (requestData) => {
	let activityManagementUrl = getActivityManagementUrl();
	let response = await apiClient.post(activityManagementUrl, requestData);
	assertError(response, activityManagementUrl);
	return response.data.data;
};

const getActivityManagementUrl = () => {
	return getActivityUrl() + "/mgmt";
};
