import { useMemo, useState } from "react";
import { UserManagementContext } from "..";
import { useUserTableData } from "../../../../../hooks/services/userManagement/user";
import { useTeamsTableData } from "../../../../../hooks/services/userManagement/teams";
import { useTerritoryList } from "../../../../../hooks/services/userManagement/territory";
import { viewTypes } from "../../../../../utils/userManagementResources";
import { useRoleTableData } from "../../../../../hooks/services/userManagement/roles";

const UserManagementProvider = (props) => {
	const { viewType = "", children } = props;

	const [searchValue, setSearchValue] = useState("");
	const [filterId, setFilterId] = useState(1);
	const [fieldToSort, setFieldToSort] = useState({});

	const isUserTableEnabled = viewType === viewTypes.users;
	const isRoleTableEnabled = viewType === viewTypes.roles;
	const isTeamTableEnabled = viewType === viewTypes.teams;
	const isTerritoryTableEnabled = viewType === viewTypes.territory;

	const userTableData = useUserTableData(
		filterId,
		searchValue,
		fieldToSort,
		isUserTableEnabled
	);
	const roleTableData = useRoleTableData(fieldToSort, isRoleTableEnabled);
	const teamTableData = useTeamsTableData(fieldToSort, isTeamTableEnabled);
	const territoryTableData = useTerritoryList(
		fieldToSort,
		isTerritoryTableEnabled
	);

	const {
		data: listData,
		isLoading: isLoadingListData,
		status: listDataStatus,
	} = useMemo(() => {
		switch (viewType) {
			case viewTypes.users:
				return userTableData;
			case viewTypes.roles:
				return roleTableData;
			case viewTypes.teams:
				return teamTableData;
			case viewTypes.territory:
				return territoryTableData;
			default:
				return { isLoading: true };
		}
	}, [
		roleTableData,
		teamTableData,
		territoryTableData,
		userTableData,
		viewType,
	]);

	const handleSearch = (value) => {
		setSearchValue(value);
	};

	const filterUserList = (value) => {
		setFilterId(value);
	};

	const handleSort = (value) => {
		setFieldToSort(value);
	};

	return (
		<UserManagementContext.Provider
			value={{
				listData: listData,
				isLoadingListData: isLoadingListData,
				listDataStatus: listDataStatus,
				handleSearch: handleSearch,
				filterUserList: filterUserList,
				handleSort: handleSort,
			}}
		>
			{children}
		</UserManagementContext.Provider>
	);
};

export { UserManagementProvider };
