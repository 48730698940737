import React from "react";
import { TableCellText } from "../../../../styles/twozo";
import { Box, useTheme } from "@mui/material";
import { useAuth } from "../../../../hooks/auth";
import { PERMISSIONS } from "../../../../utils/Auth";
import { useNavigate } from "react-router-dom";

export default function Company(props) {
	const { data } = props;
	const theme = useTheme();
	const navigate = useNavigate();
	// query call:-
	const { isUserAllowedFor } = useAuth();

	if (!data || !isUserAllowedFor(PERMISSIONS.company.view)) {
		return "-";
	}

	const navigateToCompany = () => {
		navigate(`/companies/${data?.id?.toString()}`);
	};

	return (
		<React.Fragment>
			<Box onClick={navigateToCompany}>
				<TableCellText color={theme.palette.secondary.contrastText}>
					{data?.name}
				</TableCellText>
			</Box>
		</React.Fragment>
	);
}
