import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	getTeamUsersAndUsersListKey,
	getTerritoryDataKey,
	getTerritoryTableDataKey,
	getTerritoryTableDataWithSortKey,
	getTerritoryUserListKey,
	territoryDropdownListKey,
} from "../../../../utils/queryKeys/userManagement/territory";
import {
	createTerritory,
	deleteTerritory,
	getTeamUsers,
	getTerritoryData,
	getTerritoryDropdownList,
	getTerritoryList,
	getTerritoryUsersList,
	updateTerritory,
} from "../../../../api/userManagement/territory/territory";

const useTerritoryList = (fieldToSort, enabled) => {
	return useQuery(
		getTerritoryTableDataWithSortKey(fieldToSort),
		() => getTerritoryList(fieldToSort),
		{
			select: (data) => data.territories,
			enabled: enabled,
		}
	);
};

const useTerritoryDropdownList = () => {
	return useQuery(
		territoryDropdownListKey,
		() => getTerritoryDropdownList(),
		{
			select: (data) => data?.options,
		}
	);
};

const useTerritoryUsersListData = (territoryId) => {
	let territoryUsersListKey = getTerritoryUserListKey(territoryId);
	return useQuery(
		territoryUsersListKey,
		() => getTerritoryUsersList(territoryId),
		{
			select: (data) => data.users,
			enabled: !!territoryId,
		}
	);
};

const useTerritoryData = (territoryId) => {
	let territoryDataKey = getTerritoryDataKey(territoryId);
	return useQuery(territoryDataKey, () => getTerritoryData(territoryId), {
		select: (data) => data.territory,
		enabled: !!territoryId,
	});
};

const useInvalidateTerritoryList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getTerritoryTableDataKey());
	};
};

const useInvalidateTerritoryUserList = (territoryId) => {
	let queryClient = useQueryClient();
	let territoryUserListKey = getTerritoryUserListKey(territoryId);
	return () => {
		queryClient.invalidateQueries(territoryUserListKey);
	};
};

const useInvalidateTerritoryData = (territoryId) => {
	let queryClient = useQueryClient();
	let territoryDataKey = getTerritoryDataKey(territoryId);
	return () => {
		queryClient.invalidateQueries(territoryDataKey);
	};
};

const useCreateTerritory = () => {
	let invalidateTerritoryList = useInvalidateTerritoryList();
	return useMutation(createTerritory, {
		onSuccess: () => {
			invalidateTerritoryList();
		},
	});
};

const useUpdateTerritory = (territoryId) => {
	let invalidateTerritoryList = useInvalidateTerritoryList();
	let invalidateTerritoryData = useInvalidateTerritoryData(territoryId);
	let invalidateUserList = useInvalidateTerritoryUserList(territoryId);
	return useMutation(updateTerritory, {
		onSuccess: () => {
			invalidateTerritoryList();
			invalidateTerritoryData();
			invalidateUserList();
		},
	});
};

const useDeleteTerritory = () => {
	let invalidateTerritoryList = useInvalidateTerritoryList();
	return useMutation(deleteTerritory, {
		onSuccess: () => {
			invalidateTerritoryList();
		},
	});
};

const useTeamUsersAndUsersList = () => {
	let teamUsersKey = getTeamUsersAndUsersListKey();
	return useQuery(teamUsersKey, () => getTeamUsers());
};

export {
	useTerritoryList,
	useTerritoryUsersListData,
	useCreateTerritory,
	useUpdateTerritory,
	useDeleteTerritory,
	useTerritoryData,
	useTerritoryDropdownList,
	useTeamUsersAndUsersList,
};
