import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import {
	assignOwnerToCompanies,
	createCompany,
	deleteCompany,
	getCompanyList,
	getCompanySummaryData,
	getRelatedContactsList,
	getSummaryData,
	partialUpdateCompany,
	updateCompany,
	updateCompanySummary,
} from "../../../api/company/companyApi";
import {
	getCompanyTableKey,
	getCompanyDataKey,
	getCompanyListKey,
	getRelatedContactsKey,
	getCompanySummaryKey,
	getCompanySummaryViewKey,
} from "../../../utils/queryKeys";
import { useInvalidateCompanyTimeline } from "./summary/timeline";

//Company Data
const useCompanyData = (companyId) => {
	const companyKey = getCompanyDataKey(companyId);
	return useQuery(companyKey, () => getSummaryData(companyId));
};

// Company Summary Data
const useCompanySummaryData = (companyId, hideEmptyFields) => {
	let companySummaryKey = getCompanySummaryViewKey(
		companyId,
		hideEmptyFields
	);
	return useQuery(companySummaryKey, () =>
		getCompanySummaryData(companyId, hideEmptyFields)
	);
};

//Invalidate company list
const useInvalidateCompanyTableData = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries({ queryKey: getCompanyTableKey() });
	};
};

//Invalidate company Data
const useInvalidateCompanyData = (companyId) => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getCompanyDataKey(companyId));
	};
};

//Invalidate company summary Data
const useInvalidateCompanySummaryData = (companyId) => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getCompanySummaryKey(companyId));
	};
};

//Edit Company
const useEditCompany = (companyId) => {
	let invalidateCompanyData = useInvalidateCompanyData(companyId);
	let invalidateCompanySummaryData =
		useInvalidateCompanySummaryData(companyId);
	let invalidateCompanyTimeline = useInvalidateCompanyTimeline(companyId);
	let invalidateCompanyTableData = useInvalidateCompanyTableData();
	return useMutation(updateCompanySummary, {
		onSuccess: () => {
			invalidateCompanyTableData();
			invalidateCompanyData();
			invalidateCompanySummaryData();
			invalidateCompanyTimeline();
		},
	});
};

//Delete Company
const useDeleteCompanyMutation = () => {
	let invalidateCompanyTableData = useInvalidateCompanyTableData();
	return useMutation(deleteCompany, {
		onSuccess: () => {
			invalidateCompanyTableData();
		},
	});
};

//Company create or clone
const useCreateMutation = () => {
	let invalidateCompanyTableData = useInvalidateCompanyTableData();
	return useMutation(createCompany, {
		onSuccess: () => {
			invalidateCompanyTableData();
		},
	});
};

// Related contacts List
const useRelatedContactsList = (companyId) => {
	let relatedContactsKey = getRelatedContactsKey(companyId);
	return useQuery(
		relatedContactsKey,
		() => getRelatedContactsList(companyId),
		{
			select: (data) => data?.contacts,
		}
	);
};

const useCompanyList = (isCompanyListEnabled = true) => {
	return useQuery(getCompanyListKey(), () => getCompanyList(), {
		select: (data) => data?.options,
		enabled: isCompanyListEnabled,
	});
};

//Assign owner mutation
const useAssignOwnerToCompanies = () => {
	let invalidateCompanyTableData = useInvalidateCompanyTableData();
	return useMutation(assignOwnerToCompanies, {
		onSuccess: () => {
			invalidateCompanyTableData();
		},
	});
};

// partial update
const usePartialUpdateCompany = (companyId) => {
	let invalidateSummaryData = useInvalidateCompanySummaryData(companyId);
	let invalidateCompanyTimeline = useInvalidateCompanyTimeline(companyId);
	return useMutation(partialUpdateCompany, {
		onSuccess: () => {
			invalidateSummaryData();
			invalidateCompanyTimeline();
		},
	});
};

// partial bulk update
const usePartialBulkUpdateCompany = () => {
	let invalidateCompanyTableData = useInvalidateCompanyTableData();
	return useMutation(updateCompany, {
		onSuccess: () => {
			invalidateCompanyTableData();
		},
	});
};

export {
	useCompanyData,
	useDeleteCompanyMutation,
	useCreateMutation,
	useCompanyList,
	useRelatedContactsList,
	useAssignOwnerToCompanies,
	useInvalidateCompanyTableData,
	useCompanySummaryData,
	useEditCompany,
	usePartialBulkUpdateCompany,
	usePartialUpdateCompany,
};
