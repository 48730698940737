import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import {
	useInfiniteQuery,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { getTableData } from "../../../api/table/tableApi";
import {
	Box,
	Checkbox,
	CircularProgress,
	Divider,
	Stack,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
	Badge,
	Button,
	MenuItem,
	Skeleton,
} from "@mui/material";
import { default as MuiTable } from "@mui/material/Table";
import { default as DropDownIcon } from "../../../assets/icons/dropDownCentered";
import { default as FilterIcon } from "../../../assets/icons/filter";
import { TableHeaderLabel, twozoStyles } from "../../../styles/twozo";
import Menu from "../Menu";
import { getTableDataKey, getTableKey } from "../../../utils/queryKeys";
import FilterDropdown from "./Filter/FilterDropdown";
import CountOverview from "./CountOverview";
import FilterDrawer from "./Filter/FilterDrawer";
import { useFilterSaveViewContext } from "./Filter/Context/FilterSaveViewContext";
import { useFilterDataContext } from "./Filter/Context/FilterDataContext";
import { isEmptyObject } from "../../../utils/common";
import Row from "./Row";
import AddOrRemoveColumns from "./AddOrRemoveColumns";
import { useLastRowRef } from "../../../hooks/common/InfiniteScroll";

export default function Table(props) {
	const {
		table,
		menu,
		selectedMenu,
		additionalView,
		moduleName,
		enhancedMenu,
		onRowClick,
		isActionsVisible,
		tableMenuOptions = [],
		noResultsMessage,
		hasShareViewPermissions,
	} = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const [selected, setSelected] = useState(new Set());
	const [selectedColumns, setSelectedColumns] = useState([]);
	const [selectRequest, setSelectRequest] = useState([]);
	const [sortRequest, setSortRequest] = useState([]);
	const [tableStructure, setTableStructure] = useState({});
	const [tableRowElement, setTableRowElement] = useState(null);
	const [tableMenuElement, setTableMenuElement] = useState(null);
	const isOpenTableMenu = Boolean(tableMenuElement);
	const [countSummary, setCountSummary] = useState([]);
	const tableRef = useRef(null); // Reference to find Table stating position
	const [tableStartingPosition, setTableStartingPosition] = useState(0);

	const sortTypes = {
		ascending: 1,
		decending: 0,
	};

	const styles = {
		menuItem: {
			fontWeight: 500,
			fontSize: "13px",
			color: theme.palette.primary.main,
		},
	};

	const { filterState, filterListByCondition, openSwipeableFilter } =
		useFilterDataContext();

	const { saveViewState, handleOpenSaveViewAsDialog } =
		useFilterSaveViewContext();

	const tableKey = getTableKey(table);

	const {
		isLoading: isTableStructureLoading,
		data,
		error,
	} = useQuery(
		tableKey,
		() =>
			getTableData(table, {
				fetchHits: true,
				fetchStruct: true,
				limit: { start: 1, count: 1 },
			}),
		{ refetchOnWindowFocus: false }
	);

	if (error) {
		alert("Error" + error);
	}

	useEffect(() => {
		if (!isTableStructureLoading && !error) {
			setTableStructure(data.structure);
			updateSelectedColumn(data.structure.columns);
		}
	}, [isTableStructureLoading, data, error]);

	const updateSelectedColumn = async (columns) => {
		const selectedColumns = [];
		const selectRequest = [];

		columns.forEach((column) => {
			if (!column.hidden) {
				selectedColumns.push(column);
			}
			selectRequest.push({
				field: column.name,
				isEnabled: !column.hidden,
			});
		});

		setSelectRequest(selectRequest);
		setSelectedColumns(selectedColumns);
	};

	const toggleSort = async (columnIndex) => {
		const sortRequest = [];

		setSelectedColumns((selectedColumns) => {
			if (selectedColumns[columnIndex].sort === sortTypes.ascending) {
				selectedColumns[columnIndex].sort = sortTypes.decending;
				sortRequest.push({
					field: selectedColumns[columnIndex].name,
					type: "desc",
				});
			} else {
				selectedColumns[columnIndex].sort = sortTypes.ascending;
				sortRequest.push({
					field: selectedColumns[columnIndex].name,
					type: "asc",
				});
			}

			selectedColumns.forEach((column, index) => {
				if (
					column.sort === sortTypes.ascending &&
					index !== columnIndex
				) {
					selectedColumns[index].sort = sortTypes.decending;
				}
			});

			return selectedColumns;
		});

		setSortRequest(sortRequest);
	};

	const isSelected = useCallback((id) => selected.has(id), [selected]);

	const handleCheckboxClick = (event, id) => {
		event.stopPropagation();

		if (isSelected(id)) {
			setSelected((selected) => {
				const newSelected = new Set(selected);
				newSelected.delete(id);
				return newSelected;
			});
		} else {
			setSelected((selected) => new Set([...selected, id]));
		}
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const allRowIds = tableRowsData.map((row) => row.id);
			setSelected(new Set(allRowIds));
		} else {
			setSelected(new Set());
		}
	};

	const deSelectAll = async () => {
		setSelected(new Set());
		setSelectRequest(selectRequest);
		setSelectedColumns(selectedColumns);
	};

	useEffect(() => {
		if (tableRef) {
			setTableStartingPosition(
				tableRef.current.getBoundingClientRect().top
			);
		}
	}, [tableRef]);

	const openTableMenu = useCallback((event, row) => {
		event.stopPropagation();
		setTableRowElement(row);
		setTableMenuElement(event.currentTarget);
	}, []);

	const closeTableMenu = () => {
		setTableMenuElement(null);
		setTableRowElement(null);
	};

	const defaultTableRowFetchCount = 20;

	const {
		data: tableData,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isLoading: isTableLoading,
	} = useInfiniteQuery({
		queryKey: getTableDataKey(table, [
			selectRequest,
			sortRequest,
			defaultTableRowFetchCount,
			filterState.filterConditions,
		]),
		queryFn: ({ pageParam }) => {
			return getTableData(
				table,
				{
					fields: selectRequest,
					sort: sortRequest,
					limit: pageParam
						? {
								start:
									(pageParam - 1) *
										defaultTableRowFetchCount +
									1,
								count: defaultTableRowFetchCount,
							}
						: { start: 1, count: defaultTableRowFetchCount },
					fetchHits: true,
				},
				filterState.filterConditions
			);
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		enabled:
			selectRequest.length > 0 &&
			!isEmptyObject(filterState.filterConditions),
	});

	const queryClient = useQueryClient();
	//Keeping only first page on cache to avoid refetching when user comes to this page again.
	useEffect(() => {
		return () => {
			queryClient.setQueryData(
				getTableDataKey(table, [
					selectRequest,
					sortRequest,
					defaultTableRowFetchCount,
					filterState.filterConditions,
				]),
				(currentData) => {
					return currentData
						? {
								pageParam:
									currentData.pageParams?.length > 0
										? [currentData.pageParams[0]]
										: currentData.pageParams,
								pages:
									currentData.pages?.length > 0
										? [currentData.pages[0]]
										: currentData.pages,
							}
						: undefined;
				}
			);
		};
	}, [
		filterState.filterConditions,
		queryClient,
		selectRequest,
		sortRequest,
		table,
	]);

	useEffect(() => {
		if (tableData && tableData.pages.length) {
			setCountSummary(
				tableData.pages[tableData.pages.length - 1].countSummary || []
			);
		}
	}, [tableData]);

	const tableRowsData = useMemo(() => {
		let tableRowsData = [];
		if (tableData && tableData.pages) {
			for (
				let pageCount = 0;
				pageCount < tableData.pages.length;
				pageCount++
			) {
				if (tableData.pages[pageCount].rows) {
					tableRowsData.push(...tableData.pages[pageCount].rows);
				}
			}
		}
		return tableRowsData;
	}, [tableData]);

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	return (
		<React.Fragment>
			<FilterDrawer
				moduleName={moduleName}
				hasShareViewPermissions={hasShareViewPermissions}
			/>

			<Box className={classes.menuBar}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					height="100%"
					style={{
						marginLeft: filterState.isSwipeableFilterOpened
							? "250px"
							: null,
						transition: filterState.isSwipeableFilterOpened
							? theme.transitions.create("margin", {
									easing: theme.transitions.easing.easeOut,
									duration:
										theme.transitions.duration
											.enteringScreen,
								})
							: theme.transitions.create("margin", {
									easing: theme.transitions.easing.sharp,
									duration:
										theme.transitions.duration
											.leavingScreen,
								}),
					}}
				>
					<Stack direction="row" spacing={3} alignItems="center">
						{!filterState.isSwipeableFilterOpened && (
							<Stack
								onClick={openSwipeableFilter}
								style={{ cursor: "pointer" }}
							>
								{filterState.filterCount ? (
									<Badge
										badgeContent={filterState.filterCount}
										color="primary"
									>
										{FilterIcon(
											20,
											20,
											theme.palette.primary.main
										)}
									</Badge>
								) : (
									<Stack>
										{FilterIcon(
											20,
											20,
											theme.palette.primary.main
										)}
									</Stack>
								)}
							</Stack>
						)}

						{additionalView && additionalView}

						<FilterDropdown
							moduleName={moduleName}
							hasShareViewPermissions={hasShareViewPermissions}
						/>

						{!!enhancedMenu && selected.size === 0 && (
							<Stack direction="row" spacing={2}>
								<Divider
									orientation="vertical"
									flexItem={true}
								/>

								<Box display="flex">
									{React.cloneElement(enhancedMenu, {
										filterListByCondition:
											filterListByCondition,
									})}
								</Box>
							</Stack>
						)}

						{saveViewState.isSaveViewAsButtonVisible &&
							filterState.filterCount >= 1 && (
								<Button
									variant="contained"
									color="secondary"
									disableElevation
									onClick={handleOpenSaveViewAsDialog}
								>
									Save View
								</Button>
							)}
					</Stack>

					{selected.size > 0
						? React.cloneElement(selectedMenu, {
								selected: [...selected],
								setSelected: (selected) =>
									setSelected(new Set(selected)),
								data: tableRowsData,
								deSelectAll: deSelectAll,
							})
						: menu}
				</Stack>
			</Box>

			<Menu
				minWidth="200px"
				anchorEl={tableMenuElement}
				open={isOpenTableMenu}
				onClose={closeTableMenu}
			>
				{tableMenuOptions.map((menu) => (
					<MenuItem
						key={menu.id}
						style={{ height: "40px" }}
						onClick={() => {
							menu.action(tableRowElement);
							closeTableMenu();
						}}
					>
						<Stack direction="row" spacing={2}>
							<Box display="flex">
								{menu.icon(20, 20, theme.palette.primary.main)}
							</Box>

							<Typography style={styles.menuItem}>
								{menu.name}
							</Typography>
						</Stack>
					</MenuItem>
				))}
			</Menu>

			<Box
				ref={tableRef}
				sx={{
					height: `calc(100vh - ${tableStartingPosition}px)`,
				}}
				style={{
					marginLeft: filterState.isSwipeableFilterOpened
						? "250px"
						: null,
					transition: filterState.isSwipeableFilterOpened
						? theme.transitions.create("margin", {
								easing: theme.transitions.easing.easeOut,
								duration:
									theme.transitions.duration.enteringScreen,
							})
						: theme.transitions.create("margin", {
								easing: theme.transitions.easing.sharp,
								duration:
									theme.transitions.duration.leavingScreen,
							}),
				}}
			>
				{isTableStructureLoading ? (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="60vh"
					>
						<CircularProgress />
					</Stack>
				) : (
					<TableContainer sx={{ height: "100%" }}>
						<AddOrRemoveColumns
							tableStructure={tableStructure}
							updateSelectedColumn={updateSelectedColumn}
							setTableStructure={setTableStructure}
						></AddOrRemoveColumns>

						<MuiTable sx={{ minWidth: 650 }} size="small">
							<TableHead>
								<TableRow
									sx={{
										position: "sticky",
										top: 0,
										backgroundColor: "#fff",
										zIndex: 1,
										"&:hover": {
											backgroundColor: "#fff",
										},
									}}
								>
									<TableCell padding="checkbox">
										<Checkbox
											indeterminate={
												selected.size > 0 &&
												selected.size <
													tableRowsData.length
											}
											checked={
												tableRowsData.length > 0 &&
												selected.size ===
													tableRowsData.length
											}
											onChange={handleSelectAllClick}
										/>
									</TableCell>

									{selectedColumns.map((column, index) => (
										<TableCell
											key={column.name}
											style={{ minWidth: "200px" }}
											onClick={
												column.isSortable
													? () => toggleSort(index)
													: null
											}
										>
											<Stack
												direction="row"
												spacing={0.5}
												alignItems="center"
											>
												<TableHeaderLabel
													style={{
														whiteSpace: "nowrap",
													}}
												>
													{column.displayName}
												</TableHeaderLabel>

												<Box
													sx={{
														display:
															column.isSortable
																? "flex"
																: "none",
													}}
												>
													{column.sort ===
													sortTypes.ascending ? (
														<Box
															style={{
																transform:
																	"rotate(180deg)",
															}}
															pt={0.5}
														>
															{DropDownIcon(
																13,
																13,
																"rgba(0, 0, 0, 0.6)"
															)}
														</Box>
													) : (
														DropDownIcon(
															13,
															13,
															"rgba(0, 0, 0, 0.6)"
														)
													)}
												</Box>
											</Stack>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{tableData?.pages?.length > 0 ? (
									tableData.pages.map((page) =>
										page.rows.map((row) => (
											<Row
												selected={isSelected(row.id)}
												key={row.id}
												onRowClick={onRowClick}
												row={row}
												lastRowRef={lastRowRef}
												isActionsVisible={
													isActionsVisible
												}
												handleCheckboxClick={
													handleCheckboxClick
												}
												openTableMenu={openTableMenu}
												selectedColumns={
													selectedColumns
												}
												isMoreMenuOpened={
													tableRowElement?.id ===
													row.id
												}
											></Row>
										))
									)
								) : !tableData && isTableLoading ? (
									new Array(10).fill(0).map((_, index) => (
										<TableRow key={index}>
											<TableCell padding="checkbox">
												<Skeleton
													variant="text"
													sx={{ fontSize: "13px" }}
												/>
											</TableCell>

											{selectedColumns.map((_, index) => (
												<TableCell
													key={index}
													style={{
														minWidth: "200px",
													}}
												>
													<Skeleton
														variant="text"
														sx={{
															fontSize: "13px",
														}}
													/>
												</TableCell>
											))}
										</TableRow>
									))
								) : (
									<TableRow>
										<TableCell
											colSpan={selectedColumns.length + 1}
											sx={{ borderBottom: "none" }}
										>
											<Stack
												alignItems="center"
												justifyContent="center"
												style={{
													position: "absolute",
													top: "45%",
													left: "42%",
												}}
											>
												<Typography
													fontSize={18}
													fontWeight={500}
													color="rgba(0, 0, 0, 0.6)"
												>
													{noResultsMessage.title}
												</Typography>
												<Typography
													fontSize={14}
													color="rgba(0, 0, 0, 0.6)"
												>
													{noResultsMessage.message}
												</Typography>
											</Stack>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</MuiTable>

						{!isTableLoading && isFetching && (
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="center"
								spacing={1}
								py={2}
							>
								<CircularProgress size={18} />
								<Typography fontSize={12}>
									Loading More Data
								</Typography>
							</Stack>
						)}

						<CountOverview countSummary={countSummary} />
					</TableContainer>
				)}
			</Box>
		</React.Fragment>
	);
}
