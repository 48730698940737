import React, { useEffect, useMemo, useState } from "react";
import {
	Autocomplete,
	Box,
	Button,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	MenuItem,
	Paper,
	Popper,
	Select,
	Stack,
	TextField,
	ToggleButton,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../assets/icons/close";
import { default as DealIcon } from "../../../assets/icons/deals";
import { default as ContactIcon } from "../../../assets/icons/contact";
import { default as CompanyIcon } from "../../../assets/icons/company";
import { default as ContactImage } from "../../../assets/images/contact/contact2.png";
import AddProductTable from "../AddProductTable";
import MenuSearchBar from "../../Elements/MenuSearchBar";
import { removeFieldsWithEmptyValues } from "../../../utils/common";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import {
	useDealList,
	useDealRelatedEntitiesData,
	useAddProductsToDeal,
} from "../../../hooks/services/deal";
import ToggleButtonGroup from "../../Elements/ToggleButtonGroup";
import { useCurrencies } from "../../../hooks/settings/currency";

const CustomPopper = function (props) {
	return <Popper {...props} style={{ ...props.style, padding: "4px 0px" }} />;
};

export default function AddDeal(props) {
	const theme = useTheme();
	const { productIds, onAddProductsToDeal, onClose, defaulValues, dealId } =
		props;

	const getInitialProductData = () => {
		let productPriceList = [];
		if (productIds) {
			for (let productId of productIds) {
				let productData = {
					product: productId,
					price: 0,
					quantity: 1,
					tax: 0,
					discount: 0,
				};
				productPriceList.push(productData);
			}
		} else {
			productPriceList.push({
				product: "",
				price: "",
				quantity: "",
				tax: "",
				discount: "",
			});
		}
		return productPriceList;
	};

	const taxTypes = {
		taxInclusive: "taxInclusive",
		noTax: "noTax",
	};
	const [taxType, setTaxType] = useState(taxTypes.taxInclusive);
	const [dealInputValue, setDealInputValue] = useState("");
	const [selectedDeal, setSelectedDeal] = useState({ title: "" });
	const [isDealSelected, setIsDealSelected] = useState(false);
	const [searchCurrencyValue, setSearchCurrencyValue] = useState("");
	const [productsData, setProductsData] = useState(getInitialProductData());

	const { activeCurrencyList, getCurrencyDataById, baseCurrency } =
		useCurrencies(searchCurrencyValue);
	const [currencyValue, setCurrencyValue] = useState("");

	const addProductsToDealMutation = useAddProductsToDeal(dealId);
	const { data: dealList } = useDealList();
	const { data: relatedEntitiesData } = useDealRelatedEntitiesData(
		selectedDeal?.id
	);

	const isTaxInclusive = taxType === taxTypes.taxInclusive;
	const companyValue = relatedEntitiesData?.company?.name;
	const contactValue = relatedEntitiesData?.primaryContact?.fullName;
	const dealInputEnabled = !dealId;

	const CustomPaper = function (props) {
		return (
			<Paper
				{...props}
				style={{
					...props.style,
					borderRadius: "8px",
					width: "260px",
					boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
					display: dealInputValue ? "block" : "none",
				}}
			/>
		);
	};

	useEffect(() => {
		if (baseCurrency) {
			setCurrencyValue(baseCurrency?.id);
		}
	}, [baseCurrency]);

	useEffect(() => {
		if (defaulValues) {
			if (defaulValues?.items?.length > 0) {
				setProductsData(defaulValues.items);
			}

			if (defaulValues?.currency?.id) {
				setCurrencyValue(defaulValues.currency.id);
			}

			setTaxType(
				defaulValues?.taxInclusive
					? taxTypes.taxInclusive
					: taxTypes?.noTax
			);
		}

		if (dealId) {
			let defaultDealValue = dealList?.find(
				(dealData) => dealData.id === dealId
			);
			setSelectedDeal(defaultDealValue);
		}
	}, [defaulValues, dealList, dealId]);

	const handleSelectDeal = (_, value) => {
		setSelectedDeal(value);
		setDealInputValue("");
		setIsDealSelected(!!value);
	};

	const handleDealInputChange = (event) => {
		const { value } = event.target;
		setDealInputValue(value);
	};

	const handleCurrencyValue = (event) => {
		const { value } = event.target;
		setCurrencyValue(value);
	};

	const handleSearchCurrency = (event) => {
		const { value } = event.target;
		setSearchCurrencyValue(value);
	};

	const handleSave = () => {
		let requestData = getUpdateRequestData();
		addProductsToDealMutation.mutate(requestData, {
			onSuccess: (_, payload) => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message:
						payload?.items?.length > 1
							? `${payload?.items?.length} products have been added to the deal successfully.`
							: "The product has been added to the deal successfully.",
				});
				onClose();
				!!onAddProductsToDeal && onAddProductsToDeal();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const getUpdateRequestData = () => {
		let requestData = {
			dealId: selectedDeal?.id,
			currency: currencyValue,
			taxInclusive: isTaxInclusive,
			items: [],
		};

		for (let productData of productsData) {
			if (productData?.product) {
				requestData.items.push(productData);
			}
		}
		removeFieldsWithEmptyValues(requestData);
		return requestData;
	};

	const handleTax = (_, type) => {
		if (type) {
			setTaxType(type);
		}
	};

	const updateProductsData = (updatedProductsData) => {
		setProductsData(updatedProductsData);
	};

	const selectedCurrency = useMemo(() => {
		const currencyData = getCurrencyDataById(currencyValue);
		return currencyData;
	}, [currencyValue, getCurrencyDataById]);

	const getInitialProductIds = () => {
		if (productIds) {
			return productIds;
		} else if (defaulValues?.items?.length > 0) {
			return defaulValues.items.map((productData) => productData.product);
		}
		return [];
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					No Results Found
				</Typography>
			</Stack>
		);
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
					>
						<Typography fontWeight={600}>
							Add Products to Deal
						</Typography>

						<IconButton
							onClick={props.onClose}
							size="small"
							style={{ padding: 0 }}
						>
							{CloseIcon(25, 25, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				<Divider />

				<Box
					p={2}
					style={{
						flex: "1 1 auto",
						minHeight: 0,
						overflowY: "auto",
					}}
				>
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						spacing={2}
					>
						<Grid item xs={12}>
							<Stack
								spacing={0.5}
								sx={{ opacity: dealInputEnabled ? 1 : 0.6 }}
							>
								<Typography fontSize={13} fontWeight={500}>
									Deal Name
								</Typography>

								<Autocomplete
									size="small"
									PopperComponent={CustomPopper}
									PaperComponent={CustomPaper}
									options={dealList || []}
									forcePopupIcon={false}
									onChange={handleSelectDeal}
									value={selectedDeal}
									readOnly={!dealInputEnabled}
									renderOption={(props, option) => (
										<Box
											component="li"
											key={option.id}
											{...props}
											style={{ minHeight: "40px" }}
										>
											<Typography
												fontSize={13}
												key={option.id}
											>
												{option.title}
											</Typography>
										</Box>
									)}
									getOptionLabel={(option) => {
										return option.title;
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											value={dealInputValue}
											onChange={handleDealInputChange}
											onBlur={() => setDealInputValue("")}
											inputProps={{
												...params.inputProps,
												style: {
													fontSize: "14px",
													paddingLeft: 0,
												},
											}}
											InputProps={{
												...params.InputProps,
												startAdornment: (
													<InputAdornment
														position="start"
														style={{
															paddingLeft: "8px",
														}}
													>
														{DealIcon(
															20,
															20,
															"#666666"
														)}
													</InputAdornment>
												),
											}}
											placeholder="Search"
											sx={{
												"& .MuiOutlinedInput-notchedOutline":
													{
														borderRadius: "8px",
													},
												"& .MuiAutocomplete-clearIndicator":
													{
														color: "#666666",
														display:
															selectedDeal?.title ||
															dealInputValue
																? "block"
																: "none",
													},
											}}
										/>
									)}
								/>
							</Stack>
						</Grid>

						<Grid item xs={12}>
							<Stack spacing={0.5} sx={{ opacity: "0.6" }}>
								<Typography fontSize={13} fontWeight={500}>
									Contact Person
								</Typography>

								<TextField
									sx={{
										"&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
											{
												borderColor: "#e5e5e5",
											},
									}}
									focused={false}
									value={contactValue || ""}
									InputProps={{
										readOnly: true,
										startAdornment: (
											<InputAdornment position="start">
												{isDealSelected ? (
													<img
														src={ContactImage}
														alt="ContactImage"
														width={20}
														height={20}
													/>
												) : (
													ContactIcon(
														20,
														20,
														"#666666"
													)
												)}
											</InputAdornment>
										),
									}}
								/>
							</Stack>
						</Grid>

						<Grid item xs={12}>
							<Stack spacing={0.5} sx={{ opacity: "0.6" }}>
								<Typography fontSize={13} fontWeight={500}>
									Company
								</Typography>

								<TextField
									sx={{
										"&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
											{
												borderColor: "#e5e5e5",
											},
									}}
									focused={false}
									value={companyValue || ""}
									InputProps={{
										readOnly: true,
										startAdornment: (
											<InputAdornment position="start">
												{CompanyIcon(20, 20, "#666666")}
											</InputAdornment>
										),
									}}
								/>
							</Stack>
						</Grid>

						<Grid item xs={12}>
							<Stack spacing={0.5}>
								<Typography fontSize={13} fontWeight={500}>
									Currency
								</Typography>

								<Select
									value={currencyValue}
									onChange={handleCurrencyValue}
									onOpen={() => setSearchCurrencyValue("")}
									renderValue={() => selectedCurrency?.code}
									displayEmpty
									MenuProps={{
										autoFocus: false,
										PaperProps: {
											style: {
												maxHeight: "240px",
												width: "300px",
											},
										},
									}}
								>
									<Box
										p={0.5}
										marginTop="-8px"
										sx={{
											position: "sticky",
											top: 0,
											zIndex: 1,
											backgroundColor: "#fff",
										}}
									>
										<MenuSearchBar
											value={searchCurrencyValue}
											onChange={handleSearchCurrency}
										/>
									</Box>
									{activeCurrencyList?.length > 0
										? activeCurrencyList.map(
												(currency, index) => (
													<MenuItem
														key={index}
														value={currency.id}
														sx={{
															height: "40px",
														}}
													>
														<Stack
															direction="row"
															justifyContent="space-between"
															width="100%"
														>
															<Typography fontSize="14px">
																{currency.name}
															</Typography>
															<Typography fontSize="14px">
																{currency.code}
															</Typography>
														</Stack>
													</MenuItem>
												)
											)
										: renderEmptyView()}
								</Select>
							</Stack>
						</Grid>

						<Grid item xs={12}>
							<Box pb={2}>
								<ToggleButtonGroup
									value={taxType}
									onChange={handleTax}
								>
									<ToggleButton value={taxTypes.taxInclusive}>
										Tax Inclusive
									</ToggleButton>
									<ToggleButton value={taxTypes.noTax}>
										No tax
									</ToggleButton>
								</ToggleButtonGroup>
							</Box>

							<AddProductTable
								currencyValue={selectedCurrency}
								isTaxInclusive={isTaxInclusive}
								productsData={productsData}
								updateProductsData={updateProductsData}
								productIds={getInitialProductIds()}
							/>
						</Grid>
					</Grid>
				</Box>

				<Divider />

				<Box p={2}>
					<Button
						variant="contained"
						disableElevation
						onClick={handleSave}
						disabled={addProductsToDealMutation.isLoading}
					>
						Save
					</Button>
				</Box>
			</Box>
		</React.Fragment>
	);
}
