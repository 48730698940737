import React, { useEffect, useMemo, useRef, useState } from "react";
import {
	Box,
	Button,
	Popper,
	ButtonGroup,
	Checkbox,
	Divider,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	alpha,
	useTheme,
} from "@mui/material";
import { default as DropdownIcon } from "../../../../assets/icons/dropDownCentered";
import { default as DeleteIcon } from "../../../../assets/icons/delete";
import { default as MinusIcon } from "../../../../assets/icons/minus";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { default as AttachmentIcon } from "../../../../assets/icons/attachment";
import { default as MoreIcon } from "../../../../assets/icons/more";
import { default as TrimmedContentIcon } from "../../../../assets/icons/trimmedContentIcon";
import { default as ForwardIcon } from "../../../../assets/icons/forward";
import { default as ReplyIcon } from "../../../../assets/icons/reply";
import { default as ReplyAllIcon } from "../../../../assets/icons/replyAll";
import {
	hexToRgba,
	Tooltip,
	DwarfButton,
	twozoStyles,
	rawTwozoStyles,
} from "../../../../styles/twozo";
import EmailRecipientInput from "./EmailRecipientInput";
import {
	useMailRecipientsList,
	useSendBulkMail,
	useSaveDraftMail,
	useSendMail,
	useReplyMail,
	useUploadFilesFromEmail,
	useSaveBulkDraftMail,
	useForwardMail,
} from "../../../../hooks/services/mail";
import { UnsyncedEmailComposerUI } from "./UnsyncedEmailComposerUI";
import { $generateHtmlFromNodes } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, $isDecoratorNode, $isElementNode } from "lexical";
import { $generateNodesFromDOM } from "@lexical/html";
import { removeFieldsWithEmptyValues } from "../../../../utils/common";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { emailPattern } from "../../../../utils/validation";
import BulkMailMenu from "./BulkEmailMenu";
import Menu from "../../../Elements/Menu";
import ScheduledMailUI from "./ScheduledMail";
import Subject from "./Subject";
import CCAddress from "./CCAddress";
import BCCAddress from "./BCCAddress";
import { modules } from "../../../../utils/common/ModulesName";
import { useAuth } from "../../../../hooks/auth";
import { PERMISSIONS } from "../../../../utils/Auth";
import AddFile from "../../../Summary/Files/AddFile";
import { getFormattedFileSize } from "../../../../utils/fileSizeUtils";
import { useEmailSettingsData } from "../../../../hooks/services/mail/emailSettings";
import RichTextEditor from "./RichTextEditor";

const optionsForTrackConversation = [
	{
		label: "Track Opens",
		id: "trackOpens",
	},
	{
		label: "Track Clicks",
		id: "trackClicks",
	},
];

const optionsForLinkConversation = [
	{
		label: "Link conversation to Contact",
		id: "linkConversationToContact",
	},
	{
		label: "Link conversation to Deals",
		id: "linkConversationToDeal",
	},
	{
		label: "Add Unsubscribe link",
		id: "addUnsubscribeLink",
	},
];

export default function EmailComposerUI(props) {
	const {
		togglePopout,
		multipleSender,
		fromAddress,
		onClose,
		isBulkMailEnabled,
		sourceName,
		entityId,
		composerValues,
		composerMode,
		conversationId,
		emailContext,
		trimmedContentContext,
		hasDraftMail,
		forwardMailContext,
	} = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const emailComposerRef = useRef();
	const [editor] = useLexicalComposerContext();

	const [toAddress, setToAddress] = useState([]);
	const [ccAddress, setCCAddress] = useState([]);
	const [bccAddress, setBCCAddress] = useState([]);
	const [isCCEnabled, setIsCCEnabled] = useState(false);
	const [isBCCEnabled, setIsBCCEnabled] = useState(false);
	const [bulkMailMenuElement, setBulkMailMenuElement] = useState(null);
	const isBulkMailMenuOpened = Boolean(bulkMailMenuElement);
	const [personalizedMailData, setPersonalizedMailData] = useState([]);
	const [selectedMailIdForPersonalize, setSelectedMailIdForPersonalize] =
		useState("");
	const [isHighlightEditMail, setIsHighlightEditMail] = useState(false);
	const [bodyContent, setBodyContent] = useState("");
	const [personalizedBodyContent, setPersonalizedBodyContent] = useState("");
	const [subject, setSubject] = useState("");
	const [personalizedSubject, setPersonalizedSubject] = useState("");
	const [scheduledMenuElement, setScheduledMenuElement] = useState(null);
	const isScheduledMenuOpened = Boolean(scheduledMenuElement);
	const [showDateTimePicker, setShowDateTimePicker] = useState(false);
	const [trackMenuElement, setTrackMenuElement] = useState(null);
	const isTrackMenuOpened = Boolean(trackMenuElement);
	const [detailsToTrackConversation, setDetailsToTrackConversation] =
		useState({});
	const [linkConversationMenuElement, setLinkConversationMenuElement] =
		useState(null);
	const isLinkConversationMenuOpened = Boolean(linkConversationMenuElement);
	const [detailsToLinkConversation, setDetailsToLinkConversation] = useState(
		{}
	);
	const [displayTrimmedButton, setDisplayTrimmedButton] = useState(true);
	const [isAddFileDialogOpened, setIsAddFileDialogOpened] = useState(false);
	const [addedFiles, setAddedFiles] = useState([]);
	const [uploadedFiles, setUploadedFiles] = useState([]);

	const iconColor = "#666666";
	const isEditingPersonalizedMail = !!selectedMailIdForPersonalize;
	const hasNoRecipients = toAddress?.length === 0;
	const newDraftAction = 1;
	const updateDraftAction = 2;
	const sentDraftAction = 3;
	const restrictBulkMailInput = sourceName === modules.DEAL;
	const replyMode = composerMode?.reply || composerMode?.replyAll;
	const hasMailSynced = fromAddress?.type === "Sync email";

	const { isUserAllowedFor } = useAuth();
	const isRecipientsListEnabled = isUserAllowedFor(PERMISSIONS.contact.view);
	const { data: recipients } = useMailRecipientsList(isRecipientsListEnabled);
	const replyMailMutation = useReplyMail(
		emailContext?.threadId,
		emailContext?.mailListName,
		sourceName,
		entityId
	);

	useEffect(() => {
		const getFormattedAddress = (address, isPrimaryEmailAllowed) => {
			let formattedAddress = [];
			for (let index = 0; index < address?.length; index++) {
				// filtered ToAddress Expect FromAddress
				if (address[index] !== fromAddress?.emailAddress) {
					let contactEmail = {};
					if (isPrimaryEmailAllowed) {
						contactEmail = recipients?.find(
							(recipient) =>
								recipient.value === address[index] &&
								recipient?.email?.isPrimary
						);
					} else {
						contactEmail = recipients?.find(
							(recipient) => recipient.value === address[index]
						);
					}

					if (contactEmail) {
						formattedAddress.push(contactEmail);
					} else if (address[index]?.email) {
						let toAddressForNewMailId = {
							email: { id: -1, value: address[index]?.email },
							name: "",
							value: -1,
						};
						formattedAddress.push(toAddressForNewMailId);
					}
				}
			}
			return formattedAddress;
		};

		if (composerValues) {
			if (hasDraftMail) {
				if (recipients?.length > 0) {
					if (composerValues?.toAddress?.length > 0) {
						let toAddress = composerValues?.toAddress;
						setToAddress(getFormattedAddress(toAddress));
					}

					if (composerValues?.ccAddress?.length > 0) {
						let ccAddress = composerValues?.ccAddress;
						setCCAddress(getFormattedAddress(ccAddress));
						setIsCCEnabled(true);
					}

					if (composerValues?.bccAddress?.length > 0) {
						let bccAddress = composerValues?.bccAddress;
						setBCCAddress(getFormattedAddress(bccAddress));
						setIsBCCEnabled(true);
					}
				}

				if (composerValues?.subject) {
					setSubject(composerValues?.subject);
				}

				if (composerValues?.body) {
					handleUpdateBodyContent(composerValues?.body);
				}
			} else if (replyMode) {
				let toAddress = composerValues?.toAddress || [];
				setToAddress(getFormattedAddress(toAddress));
				if (composerValues?.ccAddress?.length > 0) {
					let ccAddress = composerValues?.ccAddress || [];
					setCCAddress(getFormattedAddress(ccAddress));
					setIsCCEnabled(true);
				} else {
					setCCAddress([]);
					setIsCCEnabled(false);
				}
			} else {
				let isPrimaryEmailAllowed = true;
				setToAddress(
					getFormattedAddress(
						composerValues?.toAddress,
						isPrimaryEmailAllowed
					)
				);
			}

			if (composerValues?.subject) {
				setSubject(composerValues?.subject);
			}
		}
	}, [recipients, composerValues]);

	const { data: emailSettingsConfig } = useEmailSettingsData();

	useEffect(() => {
		if (emailSettingsConfig) {
			setDetailsToLinkConversation((detailsToLinkConversation) => {
				return {
					...detailsToLinkConversation,
					linkConversationToContact:
						emailSettingsConfig?.emailLinkerSettings
							?.isContactLinkingEnabled,
					linkConversationToDeal:
						emailSettingsConfig?.emailLinkerSettings
							?.isDealLinkingEnabled,
				};
			});

			setDetailsToTrackConversation((detailsToTrackConversation) => {
				return {
					...detailsToTrackConversation,
					trackOpens:
						emailSettingsConfig?.emailTrackerSettings
							?.isEmailTrackerEnabled,
					trackClicks:
						emailSettingsConfig?.emailTrackerSettings
							?.isLinkTrackerEnabled,
				};
			});

			if (emailSettingsConfig?.emailSignature) {
				editor.update(() => {
					const parser = new DOMParser();
					const dom = parser.parseFromString(
						emailSettingsConfig.emailSignature,
						"text/html"
					);
					const nodes = $generateNodesFromDOM(editor, dom);
					const root = $getRoot();

					let startElement = $getRoot().selectStart();
					startElement.insertLineBreak();

					nodes.forEach((node) => {
						if ($isElementNode(node) || $isDecoratorNode(node)) {
							root.append(node);
						}
					});
				});
			}
		}
	}, [emailSettingsConfig]);

	const getToAddress = () => {
		if (Array.isArray(forwardMailContext?.toAddress)) {
			for (let toAddress of forwardMailContext.toAddress) {
				return `${toAddress?.name}, <<a href="mailto:${toAddress?.mailId}" style="color: rgb(46, 168, 113); text-decoration: none">${toAddress?.mailId}</a>>`;
			}
		}
		return "";
	};

	const getForwardMailContent = () => {
		let fromAddressName = forwardMailContext?.fromAddress?.name
			? forwardMailContext.fromAddress.name
			: forwardMailContext.fromAddress?.email?.split("@")?.[0];
		let forwardMailHeader =
			"<p style='font-family: 'Lexend', Verdana, sans-serif; font-size: 14px; font-weight: 400;'>---------- Forwarded message ----------</p>";
		let fromAddressContent = `<p style="font-family: 'Lexend', Verdana, sans-serif; font-size: 14px; font-weight: 400;">From: ${fromAddressName}, <<a href="mailto:${forwardMailContext?.fromAddress?.email}" style="color: rgb(46, 168, 113); text-decoration: none">${forwardMailContext?.fromAddress?.email}</a>></p>`;
		let toAddressContent = `<p style="font-family: 'Lexend', Verdana, sans-serif; font-size: 14px; font-weight: 400;">To: ${getToAddress()}</p>`;
		let sentTime = `<p style="font-family: 'Lexend', Verdana, sans-serif; font-size: 14px; font-weight: 400;">Date: ${forwardMailContext?.sendTime}</p><p></p><p></p>`;

		let forwardMailContent = `<div>${forwardMailHeader}${fromAddressContent}${toAddressContent}${sentTime}</div>`;
		let previousBodyContent = composerValues?.body;

		if (previousBodyContent) {
			let bodyTagStartIndex = previousBodyContent.indexOf("<body");
			let bodyTagCloseIndex = previousBodyContent.indexOf("</body>");
			let bodyContent = previousBodyContent.substring(
				bodyTagStartIndex,
				bodyTagCloseIndex
			);
			bodyContent += "</body>";
			return `<div>${forwardMailContent} ${bodyContent}</div>`;
		}
	};

	useEffect(() => {
		if (composerMode?.forward && composerValues?.body) {
			const forwardMailContent = getForwardMailContent();
			editor.update(() => {
				const parser = new DOMParser();
				const dom = parser.parseFromString(
					forwardMailContent,
					"text/html"
				);
				const nodes = $generateNodesFromDOM(editor, dom);
				const root = $getRoot();
				$getRoot().clear();

				nodes.forEach((node) => {
					if ($isElementNode(node) || $isDecoratorNode(node)) {
						root.append(node);
					}
				});
			});
		}
	}, [composerMode]);

	// email composer height
	const [emailcomposerHeight, setEmailComposerHeight] = useState(0);

	useEffect(() => {
		if (!emailComposerRef.current) return;

		const resizeObserver = new ResizeObserver(() => {
			setEmailComposerHeight(
				emailComposerRef?.current?.getBoundingClientRect()?.height
			);
		});

		resizeObserver.observe(emailComposerRef.current);
		return () => resizeObserver.disconnect(); // clean up
	}, []);

	const [isPlaceHolderApplied, setIsPlaceHolderApplied] = useState(false);

	const showComposerError = useMemo(() => {
		return isPlaceHolderApplied && toAddress.length > 1;
	}, [toAddress, isPlaceHolderApplied]);

	const onUpdateComposerError = () => {
		setIsPlaceHolderApplied(true);
	};

	const sendMailMutation = useSendMail(sourceName, entityId);
	const sendBulkMailMutation = useSendBulkMail();
	const saveDraftMailMutation = useSaveDraftMail(
		sourceName,
		entityId,
		emailContext?.threadId,
		emailContext?.conversationId,
		emailContext?.mailListName
	);
	const saveBulkDraftMutation = useSaveBulkDraftMail();
	const uploadFileMutation = useUploadFilesFromEmail();
	const forwardMailMutation = useForwardMail(
		emailContext?.threadId,
		emailContext?.mailListName,
		sourceName,
		entityId
	);

	const handleClose = () => {
		setIsBCCEnabled(false);
		setIsCCEnabled(false);
		setToAddress([]);
		setCCAddress([]);
		setBCCAddress([]);
		setSubject("");
		setBulkMailMenuElement(null);
		editor.update(() => {
			$getRoot().clear();
		});
		setDetailsToTrackConversation({});
		setTrackMenuElement(null);
		setDetailsToLinkConversation({});
		setLinkConversationMenuElement(null);
		onClose();
		setScheduledMenuElement(null);
		setDisplayTrimmedButton(true);
		setIsPlaceHolderApplied(false);
		setUploadedFiles([]);
	};

	const handleSaveDraftMail = () => {
		if (
			hasMailSynced &&
			(toAddress?.length > 0 || subject || bodyContent)
		) {
			let draftAction = hasDraftMail ? updateDraftAction : newDraftAction;
			if (isBulkMailEnabled) {
				let requestData = {
					...getBulkMailRequestData(),
					draftAction: newDraftAction,
				};
				saveBulkDraftMutation.mutate(requestData, {
					onSuccess: () => {
						enqueueSnackbar({
							variant: notificationVariants.success,
							message: notificationMessage.bulkDraftSent,
						});
						handleClose();
					},
					onError: () => {
						enqueueSnackbar({
							variant: notificationVariants.error,
							message: notificationMessage.somethingWentWrong,
						});
					},
				});
			} else {
				let requestData = getDraftMailRequestData(draftAction);
				saveDraftMailMutation.mutate(requestData, {
					onSuccess: () => {
						enqueueSnackbar({
							variant: notificationVariants.success,
							message: notificationMessage.draftMailSent,
						});
						handleClose();
					},
					onError: () => {
						enqueueSnackbar({
							variant: notificationVariants.error,
							message: notificationMessage.somethingWentWrong,
						});
					},
				});
			}
		} else {
			handleClose();
		}
	};

	const handleToAddress = (value) => {
		setToAddress(value);
	};

	const handleCCAddress = (value) => {
		setCCAddress(value);
	};

	const handleBCCAddress = (value) => {
		setBCCAddress(value);
	};

	const handleSubject = (event) => {
		const { value } = event.target;

		if (isEditingPersonalizedMail) {
			setPersonalizedSubject(value);
		} else {
			setSubject(value);
		}
	};

	function parsJsonToCss(styles) {
		let css = [];
		for (let selector in styles) {
			let style = "." + selector + " {\n";

			for (let property in styles[selector]) {
				const parsedProperty = property
					.split(/(?=[A-Z])/)
					.map((property) => property.toLowerCase())
					.join("-");
				style +=
					"  " +
					parsedProperty +
					":" +
					styles[selector][property] +
					";\n";
			}

			style += "}";
			css.push(style);
		}
		return css.join("\n");
	}

	const getPreviousBodyContent = () => {
		let fromAddressName = trimmedContentContext?.fromAddress?.name
			? trimmedContentContext?.fromAddress?.name
			: trimmedContentContext?.fromAddress?.email?.split("@")?.[0];
		let trimmedContentHeader = `<p style="font-family: 'Lexend', Verdana, sans-serif; font-size: 14px">On ${trimmedContentContext.sendTime}, ${fromAddressName}, <<a href="mailto:${trimmedContentContext?.fromAddress?.email}" style="color: #2EA871; text-decoration: none">${trimmedContentContext?.fromAddress?.email}</a>> wrote:</p>`;
		let previousBodyContent = composerValues?.body;

		if (previousBodyContent) {
			let bodyTagStartIndex = previousBodyContent.indexOf("<body");
			let bodyTagCloseIndex = previousBodyContent.indexOf("</body>");
			let bodyContent = previousBodyContent.substring(
				bodyTagStartIndex,
				bodyTagCloseIndex
			);
			bodyContent += "</body>";
			return `<div>${trimmedContentHeader}<blockquote style="margin: 0 0 0 0.8ex; border-left: 1px solid rgba(204, 204, 204, 1); padding-left: 1ex">${bodyContent}</blockquote></div>`;
		}
	};

	function parseHTML(str) {
		const html = document.createElement("html");
		const head = document.createElement("head");
		const style = document.createElement("style");
		const body = document.createElement("body");
		body.style.fontSize = "14px";
		body.style.fontFamily = "Lexend";

		if (replyMode && displayTrimmedButton) {
			body.innerHTML = `<div>${str.trim()} ${getPreviousBodyContent()}</div>`;
		} else {
			body.innerHTML = str.trim();
		}

		let classesMapper = {};
		let classNames = new Set();
		let nodes = parseHTMLNode(body, 1, classNames);
		let stylesJson = {};

		Object.keys(classes).forEach((key) => {
			classesMapper[classes[key]] = key;
		});

		classNames.forEach((className) => {
			stylesJson[className] = rawTwozoStyles[classesMapper[className]];
		});

		let stylesCss = parsJsonToCss(stylesJson);

		let textNode = document.createTextNode(stylesCss);
		style.appendChild(textNode);
		head.appendChild(style);
		html.appendChild(head);
		html.appendChild(nodes);
		return html.outerHTML;
	}

	function parseHTMLNode(node, level, classNames) {
		const className = node.getAttribute("class");
		if (className) {
			classNames.add(className);
		}

		const indentBefore = new Array(level++ + 1).join("  ");
		const indentAfter = new Array(level - 1).join("  ");
		let textNode;

		for (let i = 0; i < node.children.length; i++) {
			textNode = document.createTextNode("\n" + indentBefore);
			node.insertBefore(textNode, node.children[i]);
			parseHTMLNode(node.children[i], level, classNames);

			if (node.lastElementChild === node.children[i]) {
				textNode = document.createTextNode("\n" + indentAfter);
				node.appendChild(textNode);
			}
		}

		return node;
	}

	const getFormatedMailAddress = (address) => {
		if (address.length > 0) {
			let addressForRequest = {};
			addressForRequest.emails = [];
			addressForRequest.contactEmails = [];
			for (let index = 0; index < address?.length; index++) {
				if (address[index]?.value === -1) {
					addressForRequest.emails.push(address[index]?.email?.value);
				} else {
					addressForRequest.contactEmails.push(
						address[index].email.id
					);
				}
			}
			return addressForRequest;
		}

		return "";
	};

	const getAttachmentIds = () => {
		if (uploadedFiles?.length > 0) {
			return uploadedFiles?.map((file) => file?.id);
		}
		return [];
	};

	const getSendMailRequestData = (scheduledTime) => {
		let editorState = editor.getEditorState();
		let htmlBodyContent = editorState.read(() => {
			return parseHTML($generateHtmlFromNodes(editor), classes);
		});

		let sendMailRequestData = {
			fromAddress: fromAddress?.id,
			toAddress: getFormatedMailAddress(toAddress),
			ccAddress: getFormatedMailAddress(ccAddress),
			bccAddress: getFormatedMailAddress(bccAddress),
			message: htmlBodyContent,
			subject: subject,
			scheduledTime: scheduledTime,
			isEmailTrackerEnabled: !!detailsToTrackConversation.trackOpens,
			isLinkTrackerEnabled: !!detailsToTrackConversation.trackClicks,
			isContactLinkingEnabled:
				detailsToLinkConversation.linkConversationToContact,
			isDealLinkingEnabled:
				detailsToLinkConversation.linkConversationToDeal,
			hasUnsubscribedLink: detailsToLinkConversation.addUnsubscribeLink,
			conversationId: conversationId,
			attachmentIds: getAttachmentIds(),
			source: sourceName,
			entityId: entityId,
		};
		removeFieldsWithEmptyValues(sendMailRequestData);

		return sendMailRequestData;
	};

	const getDraftOutboundType = () => {
		let outboundTypes = {
			reply: 2,
			replyAll: 3,
			forward: 4,
		};

		const isReplyAllMode =
			replyMode &&
			composerValues?.toAddress?.length > 1 &&
			composerValues?.ccAddress?.length > 1;

		if (isReplyAllMode) {
			return outboundTypes.replyAll;
		} else if (replyMode) {
			return outboundTypes.reply;
		}
		return;
	};

	const getDraftMailRequestData = (draftAction, scheduledTime) => {
		let editorState = editor.getEditorState();
		let htmlBodyContent = editorState.read(() => {
			return parseHTML($generateHtmlFromNodes(editor), classes);
		});

		let draftMailRequestData = {
			fromAddress: fromAddress?.id,
			draftAction: draftAction,
			outboundType: getDraftOutboundType(),
			conversationId:
				conversationId || composerValues?.draftConversationId,
			toAddress: getFormatedMailAddress(toAddress),
			ccAddress: getFormatedMailAddress(ccAddress),
			bccAddress: getFormatedMailAddress(bccAddress),
			message: htmlBodyContent,
			subject: subject,
			scheduledTime: scheduledTime,
			attachmentIds: getAttachmentIds(),
			isEmailTrackerEnabled: !!detailsToTrackConversation.trackOpens,
			isLinkTrackerEnabled: !!detailsToTrackConversation.trackClicks,
			isContactLinkingEnabled:
				detailsToLinkConversation.linkConversationToContact,
			isDealLinkingEnabled:
				detailsToLinkConversation.linkConversationToDeal,
			hasUnsubscribedLink: detailsToLinkConversation.addUnsubscribeLink,
			source: sourceName,
			entityId: entityId,
		};
		removeFieldsWithEmptyValues(draftMailRequestData);

		return draftMailRequestData;
	};

	const handleSend = () => {
		if (isBulkMailEnabled) {
			handleSendBulkMail();
		} else if (isIncludesInvalidMails()) {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.invalidMailAddress,
			});
		} else if (hasDraftMail) {
			let draftMailRequestData = getDraftMailRequestData(sentDraftAction);

			saveDraftMailMutation.mutate(draftMailRequestData, {
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.mailSent,
					});
					handleClose();
				},
				onError: (error) => {
					error = JSON.parse(error?.message);
					let errorMessage = error?.message;

					enqueueSnackbar({
						variant: notificationVariants.error,
						message:
							errorMessage ??
							notificationMessage.somethingWentWrong,
					});
				},
			});
		} else if (replyMode) {
			let sendMailRequestData = getSendMailRequestData();
			replyMailMutation.mutate(sendMailRequestData, {
				onSuccess: () => {
					handleClose();
				},
				onError: (error) => {
					error = JSON.parse(error?.message);
					let errorMessage = error?.message;

					enqueueSnackbar({
						variant: notificationVariants.error,
						message:
							errorMessage ??
							notificationMessage.somethingWentWrong,
					});
				},
			});
		} else if (composerMode?.forward) {
			let sendMailRequestData = getSendMailRequestData();
			forwardMailMutation.mutate(sendMailRequestData, {
				onSuccess: () => {
					handleClose();
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.somethingWentWrong,
					});
				},
			});
		} else {
			let sendMailRequestData = getSendMailRequestData();

			sendMailMutation.mutate(sendMailRequestData, {
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.mailSent,
					});
					handleClose();
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.somethingWentWrong,
					});
				},
			});
		}
	};

	const isIncludesInvalidMails = () => {
		const allMailAddress = [...toAddress, ...ccAddress, ...bccAddress];
		let isIncludesInvalidMails = allMailAddress.some(
			(emailData) => !emailPattern.test(emailData.email.value)
		);
		return isIncludesInvalidMails;
	};

	const handleMinimize = () => {
		setBulkMailMenuElement(null);
		togglePopout();
	};

	const openBulkMailMenu = () => {
		setBulkMailMenuElement(emailComposerRef.current);
	};

	const closeBulkMailMenu = () => {
		if (isEditingPersonalizedMail) {
			setIsHighlightEditMail(true);
		} else {
			setBulkMailMenuElement(null);
		}
	};

	const onRemoveToAddress = (removedIndex) => {
		setToAddress((toAddress) =>
			toAddress.filter((_, index) => index !== removedIndex)
		);
		let removedToAddress = toAddress[removedIndex];
		setPersonalizedMailData((personalizedMailData) =>
			personalizedMailData.filter((personalizeData) => {
				if (removedToAddress.id === -1) {
					return (
						personalizeData.email !== removedToAddress.email.value // new mail
					);
				} else {
					return (
						personalizeData.emailId !== removedToAddress.email.id
					);
				}
			})
		);
	};

	const handleBodyContent = (editorState) => {
		const editorStateTextString = editorState.read(() =>
			$getRoot().getTextContent()
		);
		let htmlBodyContent = editorState.read(() => {
			return parseHTML($generateHtmlFromNodes(editor), classes);
		});

		if (isEditingPersonalizedMail) {
			setPersonalizedBodyContent(htmlBodyContent);
		} else {
			setBodyContent(editorStateTextString.trim() ? htmlBodyContent : "");
		}
	};

	const handleSelectPersonalizeMail = (emailData) => {
		setSelectedMailIdForPersonalize(emailData.email.value);

		let updatedSubject = isMailAlreadyPersonalized(emailData)
			? getSelectedPersolizedMail(emailData).edit.subject
			: subject;
		setPersonalizedSubject(updatedSubject);

		let updatedBodyContent = isMailAlreadyPersonalized(emailData)
			? getSelectedPersolizedMail(emailData).edit.message
			: bodyContent;
		setPersonalizedBodyContent(updatedBodyContent);
		handleUpdateBodyContent(updatedBodyContent);
	};

	const handleUpdateBodyContent = (bodyContent) => {
		editor.update(() => {
			const parser = new DOMParser();
			const dom = parser.parseFromString(bodyContent, "text/html");
			const nodes = $generateNodesFromDOM(editor, dom);
			const root = $getRoot();
			$getRoot().clear();

			nodes.forEach((node) => {
				if ($isElementNode(node) || $isDecoratorNode(node)) {
					root.append(node);
				}
			});
		});
	};

	const handleCancelPersonalizeMail = () => {
		handleUpdateBodyContent(bodyContent);
		setSelectedMailIdForPersonalize("");
		setIsHighlightEditMail(false);
	};

	const handleSavePersonalizedMail = (recipient) => {
		let personalizedData = {};
		if (recipient.email.id === -1) {
			personalizedData.email = recipient.email.value; // new EmailId
		} else {
			personalizedData.emailId = recipient.email.id;
		}
		personalizedData.edit = {
			subject: personalizedSubject,
			message: personalizedBodyContent,
		};
		setPersonalizedMailData([...personalizedMailData, personalizedData]);
		handleUpdateBodyContent(bodyContent);
		setSelectedMailIdForPersonalize("");
		setIsHighlightEditMail(false);
	};

	const isMailAlreadyPersonalized = (recipient) => {
		if (recipient.email.id === -1) {
			return personalizedMailData.some(
				(personalizedData) =>
					personalizedData.email === recipient.email.value
			);
		} else {
			return personalizedMailData.some(
				(personalizedData) =>
					personalizedData.emailId === recipient.email.id
			);
		}
	};

	const getSelectedPersolizedMail = (mailData) => {
		let selectedPersonalizedMail = personalizedMailData.find(
			(personalizedData) => {
				if (mailData.email.id === -1) {
					return personalizedData.email === mailData.email.value;
				} else {
					return personalizedData.emailId === mailData.email.id;
				}
			}
		);
		return selectedPersonalizedMail;
	};

	const getFormattedBulkMailAdrress = () => {
		if (toAddress.length > 0) {
			let addressForRequest = {};
			addressForRequest.emails = [];
			addressForRequest.contactEmails = [];
			for (let index = 0; index < toAddress?.length; index++) {
				if (toAddress[index]?.value === -1) {
					addressForRequest.emails.push({
						id: toAddress[index]?.email?.value,
					});
				} else {
					addressForRequest.contactEmails.push({
						id: toAddress[index]?.email?.id,
					});
				}
			}
			return addressForRequest;
		}

		return "";
	};

	const getBulkMailRequestData = (scheduledTime) => {
		let bulkMailRequestData = {
			fromAddress: fromAddress?.id,
			toAddress: getFormattedBulkMailAdrress(),
			entityIds: toAddress
				.filter((address) => address.email.id !== -1)
				.map((address) => address.value),
			message: bodyContent,
			subject: subject,
			source: sourceName,
			personalize: personalizedMailData,
			scheduledTime: scheduledTime,
			isEmailTrackerEnabled: !!detailsToTrackConversation.trackOpens,
			isLinkTrackerEnabled: !!detailsToTrackConversation.trackClicks,
			isContactLinkingEnabled:
				detailsToLinkConversation.linkConversationToContact,
			isDealLinkingEnabled:
				detailsToLinkConversation.linkConversationToDeal,
			hasUnsubscribedLink: detailsToLinkConversation.addUnsubscribeLink,
			attachmentIds: getAttachmentIds(),
		};
		removeFieldsWithEmptyValues(bulkMailRequestData);

		return bulkMailRequestData;
	};

	const handleSendBulkMail = () => {
		if (isEditingPersonalizedMail) {
			setIsHighlightEditMail(true);
		} else {
			let bulkMailRequestData = getBulkMailRequestData();
			sendBulkMailMutation.mutate(bulkMailRequestData, {
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.mailSent,
					});
					handleClose();
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.somethingWentWrong,
					});
				},
			});
		}
	};

	const openScheduledMenu = (event) => {
		setScheduledMenuElement(event?.currentTarget);
		setShowDateTimePicker(false);
	};

	const closeScheduledMenu = () => {
		setScheduledMenuElement(null);
	};

	const onScheduledMail = (scheduledTime) => {
		if (isBulkMailEnabled) {
			let bulkMailRequestData = getBulkMailRequestData(scheduledTime);
			sendBulkMailMutation.mutate(bulkMailRequestData, {
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.mailScheduled,
					});
					closeScheduledMenu();
					handleClose();
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.somethingWentWrong,
					});
				},
			});
		} else if (isIncludesInvalidMails()) {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.invalidMailAddress,
			});
		} else if (hasDraftMail) {
			let draftMailRequestData = getDraftMailRequestData(
				sentDraftAction,
				scheduledTime
			);
			saveDraftMailMutation.mutate(draftMailRequestData, {});
		} else {
			let sendMailRequestData = getSendMailRequestData(scheduledTime);

			sendMailMutation.mutate(sendMailRequestData, {
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.mailScheduled,
					});
					closeScheduledMenu();
					handleClose();
				},
				onError: (error) => {
					error = JSON.parse(error?.message);
					let errorMessage = error?.message;

					enqueueSnackbar({
						variant: notificationVariants.error,
						message:
							errorMessage ??
							notificationMessage.somethingWentWrong,
					});
				},
			});
		}
	};

	const openConversationTrackMenu = (event) => {
		setTrackMenuElement(event.currentTarget);
	};

	const closeConversationTrackMenu = () => {
		setTrackMenuElement(null);
	};

	const handleTrackConversation = (trackOption) => {
		setDetailsToTrackConversation((detailsToTrackConversation) => {
			detailsToTrackConversation[trackOption.id] =
				!detailsToTrackConversation[trackOption.id];
			return { ...detailsToTrackConversation };
		});
	};

	const openLinkConversationMenu = (event) => {
		setLinkConversationMenuElement(event?.currentTarget);
	};

	const closeLinkConversationMenu = () => {
		setLinkConversationMenuElement(null);
	};

	const handleSelectConversationLink = (conversationLink) => {
		setDetailsToLinkConversation((detailsToLinkConversation) => {
			detailsToLinkConversation[conversationLink.id] =
				!detailsToLinkConversation[conversationLink.id];
			return { ...detailsToLinkConversation };
		});
	};

	const isSendButtonDiabled = () => {
		return (
			sendMailMutation.isLoading ||
			isScheduledMenuOpened ||
			sendBulkMailMutation.isLoading ||
			replyMailMutation.isLoading ||
			forwardMailMutation.isLoading ||
			hasNoRecipients ||
			showComposerError
		);
	};

	const handleTrimmedContent = () => {
		setDisplayTrimmedButton(false);
		let trimmedContent = getPreviousBodyContent();

		editor.update(() => {
			const parser = new DOMParser();
			const dom = parser.parseFromString(trimmedContent, "text/html");
			const nodes = $generateNodesFromDOM(editor, dom);
			const root = $getRoot();
			let startElement = $getRoot().selectStart();
			startElement.insertLineBreak();

			nodes.forEach((node) => {
				if ($isElementNode(node) || $isDecoratorNode(node)) {
					root.append(node);
				}
			});
		});
	};

	const openAddFileDialog = () => {
		setIsAddFileDialogOpened(true);
	};

	const closeAddFileDialog = () => {
		setIsAddFileDialogOpened(false);
		setAddedFiles([]);
	};

	const onFilesChange = (updatedFiles) => {
		setAddedFiles(updatedFiles);
	};

	const handleUploadFiles = () => {
		uploadFileMutation.mutate(addedFiles, {
			onSuccess: (response) => {
				setUploadedFiles(response?.createdRecords);
				closeAddFileDialog();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const removeUploadedFile = (removedFileId) => {
		if (!uploadFileMutation.isLoading) {
			setUploadedFiles(
				uploadedFiles.filter(
					(uploadedFile) => uploadedFile.id !== removedFileId
				)
			);
		}
	};

	const onUpdateSubject = (mailSubject) => {
		setSubject(mailSubject);
	};

	const getComposerReferenceIcon = () => {
		if (composerMode?.reply) {
			return (
				<Tooltip title="Reply" placement="top">
					{ReplyIcon(20, 20, "rgba(0, 0, 0, 0.6)")}
				</Tooltip>
			);
		} else if (composerMode?.replyAll) {
			return (
				<Tooltip title="Reply All" placement="top">
					{ReplyAllIcon(20, 20, "rgba(0, 0, 0, 0.6)")}
				</Tooltip>
			);
		} else if (composerMode?.forward) {
			return (
				<Tooltip title="Forward" placement="top">
					{ForwardIcon(20, 20, "rgba(0, 0, 0, 0.6)")}
				</Tooltip>
			);
		}
		return "";
	};

	return (
		<Box
			style={{
				width: "100%",
				height: "600px",
				position: "relative",
				overflowY: "auto",
				display: "flex",
				flexDirection: "column",
			}}
			ref={emailComposerRef}
		>
			{/*File upload dialog*/}
			<AddFile
				isAddFileDialogOpened={isAddFileDialogOpened}
				onCloseAddFileDialog={closeAddFileDialog}
				onFilesChange={onFilesChange}
				addedFiles={addedFiles}
				onSaveAddedFiles={handleUploadFiles}
				isSaveButtonDisabled={uploadFileMutation.isLoading}
			/>

			{/*Bulk mail Menu*/}
			<Popper
				open={isBulkMailMenuOpened}
				anchorEl={bulkMailMenuElement}
				placement="left-start"
				style={{
					zIndex: theme.zIndex.drawer + 1,
				}}
			>
				<Box
					style={{
						width: "260px",
						height: `${emailcomposerHeight}px`,
						border: `1px solid ${theme.palette.divider}`,
						borderBottom: "none",
						borderRadius: "8px 0px 0px 0px",
						backgroundColor: "#fff",
						overflowY: "auto",
					}}
				>
					<BulkMailMenu
						toAddress={toAddress}
						onCloseBulkMailMenu={closeBulkMailMenu}
						onRemoveToAddress={onRemoveToAddress}
						handleSelectPersonalizeMail={
							handleSelectPersonalizeMail
						}
						handleCancelPersonalizeMail={
							handleCancelPersonalizeMail
						}
						handleSavePersonalizedMail={handleSavePersonalizedMail}
						isMailAlreadyPersonalized={isMailAlreadyPersonalized}
						isHighlightEditMail={isHighlightEditMail}
					/>
				</Box>
			</Popper>

			{/*Scheduled Menu*/}
			<Menu
				minWidth="160px"
				anchorEl={scheduledMenuElement}
				open={isScheduledMenuOpened}
				onClose={closeScheduledMenu}
				anchorOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				sx={{ transform: "translateX(-58px) translateY(-4px)" }}
				menuListProps={{
					padding: showDateTimePicker ? "0px 0px" : "8px 0px",
				}}
			>
				{showDateTimePicker ? (
					<Box style={{ width: "560px" }}>
						<ScheduledMailUI
							onScheduledMail={onScheduledMail}
							disableScheduledButton={sendMailMutation.isLoading}
						/>
					</Box>
				) : (
					<MenuItem
						style={{ height: "40px" }}
						onClick={() => setShowDateTimePicker(true)}
					>
						<Typography fontSize={13}>Schedule Mail</Typography>
					</MenuItem>
				)}
			</Menu>

			{/*Link Conversation Menu*/}
			<Menu
				minWidth="250px"
				anchorEl={linkConversationMenuElement}
				open={isLinkConversationMenuOpened}
				onClose={closeLinkConversationMenu}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				transformOrigin={{ vertical: "bottom", horizontal: "left" }}
			>
				{optionsForLinkConversation.map((conversationLink) => (
					<MenuItem
						key={conversationLink.id}
						style={{ height: "40px" }}
						onClick={() =>
							handleSelectConversationLink(conversationLink)
						}
					>
						<Stack direction="row" spacing={1} alignItems="center">
							<Checkbox
								checked={
									!!detailsToLinkConversation[
										conversationLink.id
									]
								}
							/>
							<Typography fontSize={13}>
								{conversationLink.label}
							</Typography>
						</Stack>
					</MenuItem>
				))}
			</Menu>

			{/*Tracking Menu*/}
			<Menu
				minWidth="170px"
				anchorEl={trackMenuElement}
				open={isTrackMenuOpened}
				onClose={closeConversationTrackMenu}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				transformOrigin={{ vertical: "bottom", horizontal: "left" }}
			>
				{optionsForTrackConversation.map((trackOption) => (
					<MenuItem
						key={trackOption.id}
						style={{ height: "40px" }}
						onClick={() => handleTrackConversation(trackOption)}
					>
						<Stack direction="row" spacing={1} alignItems="center">
							<Checkbox
								checked={
									!!detailsToTrackConversation[trackOption.id]
								}
							/>
							<Typography fontSize={13}>
								{trackOption.label}
							</Typography>
						</Stack>
					</MenuItem>
				))}
			</Menu>

			{/*Menu Bar*/}
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
				py={1}
				px={2}
				style={{
					backgroundColor: hexToRgba(
						theme.palette.primary.main,
						0.12
					),
					borderRadius: isBulkMailMenuOpened
						? "0px 8px 0px 0px"
						: "8px 8px 0px 0px",
				}}
			>
				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					spacing={2}
				>
					{composerMode ? (
						<Box display="flex">{getComposerReferenceIcon()}</Box>
					) : null}

					<Typography fontSize={13} fontWeight={500}>
						{fromAddress?.emailAddress}
					</Typography>

					{multipleSender && DropdownIcon(14, 14, iconColor)}
				</Stack>

				<Stack direction="row" alignItems="center" spacing={2}>
					<Tooltip title="Discard" placement="top">
						<IconButton
							size="small"
							onClick={handleClose}
							style={{ padding: 0 }}
						>
							{DeleteIcon(18, 18, iconColor)}
						</IconButton>
					</Tooltip>

					<Tooltip title="Minimize" placement="top">
						<IconButton size="small" onClick={handleMinimize}>
							{MinusIcon(12, 2, iconColor)}
						</IconButton>
					</Tooltip>

					<Tooltip title="Close" placement="top">
						<IconButton
							size="small"
							onClick={handleSaveDraftMail}
							style={{ padding: 0 }}
						>
							{CloseIcon(18, 18, iconColor)}
						</IconButton>
					</Tooltip>
				</Stack>
			</Stack>

			{/*UnsyncedEmail*/}
			{!hasMailSynced ? (
				<Box
					style={{
						position: "absolute",
						top: "34px",
						right: 0,
						bottom: 0,
						left: 0,
						zIndex: 1,
						backgroundColor: "rgba(255, 255, 255, 0.6)",
					}}
				>
					<Box
						style={{
							marginTop: "130px",
						}}
					>
						<UnsyncedEmailComposerUI fromAddress={fromAddress} />
					</Box>
				</Box>
			) : null}

			<Box
				style={{
					flex: "1 1 auto",
					minHeight: 0,
					overflowY: "auto",
				}}
			>
				{/*Address Bar*/}
				{/*To*/}
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					style={{
						borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
					}}
					spacing={1}
				>
					<Box flexGrow={1}>
						<Stack
							direction="row"
							alignItems="center"
							pl={2}
							spacing={1}
						>
							<Box>
								<Typography fontSize={13} noWrap>
									To :
								</Typography>
							</Box>

							<Box flexGrow={1}>
								{isBulkMailEnabled ? (
									<Stack direction="row" alignItems="center">
										{toAddress?.length > 0 ? (
											selectedMailIdForPersonalize ? (
												<Box
													sx={{
														bgcolor:
															theme.palette
																.secondary.main,
														padding: "4px 10px",
														borderRadius: "8px",
														opacity: 0.7,
													}}
												>
													<Typography
														fontSize={14}
														fontWeight={500}
														color={
															theme.palette
																.primary.main
														}
														noWrap
													>
														{
															selectedMailIdForPersonalize
														}
													</Typography>
												</Box>
											) : (
												<Stack
													width="120px"
													style={{
														pointerEvents:
															restrictBulkMailInput
																? "none"
																: "auto",
													}}
												>
													<DwarfButton
														variant="contained"
														color="secondary"
														disableElevation
														onClick={
															openBulkMailMenu
														}
													>
														<Typography
															fontSize={13}
															fontWeight={500}
															color={
																restrictBulkMailInput
																	? hexToRgba(
																			theme
																				.palette
																				.secondary
																				.contrastText,
																			"0.6"
																		)
																	: theme
																			.palette
																			.secondary
																			.contrastText
															}
															noWrap
														>
															{`${toAddress.length} Recipients`}
														</Typography>
													</DwarfButton>
												</Stack>
											)
										) : null}

										<Box width="100%">
											<EmailRecipientInput
												recipients={recipients || []}
												value={toAddress || []}
												handleRecipientsChange={
													handleToAddress
												}
												renderTags={() => null}
												disabled={restrictBulkMailInput}
											/>
										</Box>
									</Stack>
								) : (
									<EmailRecipientInput
										recipients={recipients || []}
										value={toAddress || []}
										handleRecipientsChange={handleToAddress}
									/>
								)}
							</Box>
						</Stack>
					</Box>

					{!isBulkMailEnabled ? (
						<Stack
							direction="row"
							alignItems="center"
							spacing={1}
							px={1}
						>
							{!isCCEnabled && (
								<Button
									sx={{
										minWidth: "auto",
										height: "auto",
										padding: "4px",
									}}
									onClick={() => setIsCCEnabled(true)}
								>
									<Typography fontSize={14} fontWeight={500}>
										Cc
									</Typography>
								</Button>
							)}

							{!isBCCEnabled && (
								<Button
									sx={{
										minWidth: "auto",
										height: "auto",
										padding: "4px",
									}}
									onClick={() => setIsBCCEnabled(true)}
								>
									<Typography fontSize={14} fontWeight={500}>
										Bcc
									</Typography>
								</Button>
							)}
						</Stack>
					) : null}
				</Stack>

				{/*CC*/}
				<Box
					style={{
						borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
					}}
					hidden={!isCCEnabled}
				>
					<CCAddress
						recipients={recipients || []}
						value={ccAddress}
						handleRecipientsChange={handleCCAddress}
					/>
				</Box>

				{/*BCC*/}
				<Box
					style={{
						borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
					}}
					hidden={!isBCCEnabled}
				>
					<BCCAddress
						recipients={recipients || []}
						value={bccAddress}
						handleRecipientsChange={handleBCCAddress}
					/>
				</Box>

				{/*Subject*/}
				<Stack
					direction="row"
					alignItems="center"
					style={{
						borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
					}}
					px={2}
				>
					<Typography fontSize={13}>Sub :</Typography>

					<Box flexGrow={1}>
						<Subject
							value={
								isEditingPersonalizedMail
									? personalizedSubject
									: subject
							}
							onChange={handleSubject}
						/>
					</Box>
				</Stack>

				{/*Rich Text Editor*/}
				<Box>
					<RichTextEditor
						onChange={handleBodyContent}
						toAddress={toAddress}
						onUpdateSubject={onUpdateSubject}
						showComposerError={showComposerError}
						onUpdateComposerError={onUpdateComposerError}
						subject={subject}
					/>
				</Box>
			</Box>

			<Box>
				{replyMode && displayTrimmedButton ? (
					<Box pl={1.5}>
						<IconButton
							sx={{ padding: 0 }}
							onClick={handleTrimmedContent}
						>
							{TrimmedContentIcon()}
						</IconButton>
					</Box>
				) : null}

				{uploadedFiles?.length > 0 ? (
					<Stack
						px={1.5}
						direction="row"
						spacing={0.5}
						flexWrap="wrap"
					>
						{uploadedFiles.map((file) => (
							<Stack
								key={file.id}
								direction="row"
								alignItems="center"
								pb={0.5}
							>
								<Stack
									direction="row"
									alignItems="center"
									borderRadius={2}
									bgcolor={theme.palette.secondary.main}
									p={0.5}
									spacing={0.6}
								>
									<Typography fontSize={14} component="span">
										{file.name}{" "}
										<Typography
											fontSize={14}
											component="span"
											color="rgba(0, 0, 0, 0.6)"
										>
											{`(${getFormattedFileSize(
												file.size
											)})`}
										</Typography>
									</Typography>

									<IconButton
										style={{
											padding: "0px",
										}}
										onClick={() =>
											removeUploadedFile(file.id)
										}
									>
										{CloseIcon(
											16,
											16,
											"rgba(0, 0, 0, 0.6)"
										)}
									</IconButton>
								</Stack>
							</Stack>
						))}
					</Stack>
				) : null}

				<Stack
					p={1.5}
					direction="row"
					alignItems="center"
					justifyContent="space-between"
				>
					<Stack direction="row" alignItems="center" spacing={2}>
						<ButtonGroup disabled={isSendButtonDiabled()}>
							<Button
								variant="contained"
								disableElevation
								onClick={handleSend}
								sx={{
									"&.Mui-disabled": {
										backgroundColor: "rgba(51, 188, 126)",
										color: alpha("#FFFFFF", 0.6),
									},
								}}
								style={{ padding: "10px 1px 10px 16px" }}
							>
								{isBulkMailEnabled ? "Send Separately" : "Send"}
								<Divider
									orientation="vertical"
									flexItem
									sx={{
										bgcolor: "rgba(255, 255, 255, 0.4)",
										ml: 1,
										height: "100%",
									}}
								/>
							</Button>

							<Button
								variant="contained"
								disableElevation
								style={{ padding: "8px", paddingRight: "10px" }}
								onClick={openScheduledMenu}
								sx={{
									"&.Mui-disabled": {
										backgroundColor: "rgba(51, 188, 126)",
										color: alpha("#FFFFFF", 0.6),
									},
								}}
							>
								<Box
									display="flex"
									style={{
										transform: isScheduledMenuOpened
											? "rotate(180deg)"
											: "rotate(0deg)",
									}}
								>
									{DropdownIcon(
										20,
										20,
										hasNoRecipients
											? alpha("#FFFFFF", 0.6)
											: "#fff"
									)}
								</Box>
							</Button>
						</ButtonGroup>

						<Tooltip title="Attach Files">
							<IconButton onClick={openAddFileDialog}>
								{AttachmentIcon(20, 20, iconColor)}
							</IconButton>
						</Tooltip>

						<Divider
							flexItem
							orientation="vertical"
							style={{ margin: "8px 8px 8px 8px" }}
						/>

						<IconButton
							style={{ margin: 0 }}
							onClick={openLinkConversationMenu}
						>
							{MoreIcon(20, 20, iconColor)}
						</IconButton>
					</Stack>

					<Box>
						<Button
							variant="contained"
							color="secondary"
							disableElevation
							onClick={openConversationTrackMenu}
						>
							<Checkbox
								checked={
									!!detailsToTrackConversation.trackOpens ||
									!!detailsToTrackConversation.trackClicks
								}
								style={{
									padding: "0px",
								}}
							/>
							<Typography pl={0.5} fontSize={13} color="#000">
								Track Emails
							</Typography>
						</Button>
					</Box>
				</Stack>
			</Box>
		</Box>
	);
}
