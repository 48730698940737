import React from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import NoteItem from "../NoteItem";
import { useLastRowRef } from "../../../../hooks/common/InfiniteScroll";
import { useNoteList } from "../../../../hooks/services/summary/notes/notes";

export default function NoteList(props) {
	const { sourceName, entityId, handleEditNote, editedNoteId } = props;

	const {
		data: noteList,
		isLoading: isLoadingNoteList,
		hasNextPage,
		isFetching,
		fetchNextPage,
	} = useNoteList(sourceName, entityId);

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	return (
		<React.Fragment>
			<Box>
				{isLoadingNoteList ? (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="50vh"
					>
						<CircularProgress size="30px" />
					</Stack>
				) : noteList?.length > 0 ? (
					noteList?.map((note) => (
						<Box key={note?.id}>
							<NoteItem
								noteData={note}
								sourceName={sourceName}
								entityId={entityId}
								handleEditNote={handleEditNote}
								noteActionsRestricted={
									note?.id === editedNoteId
								}
							/>
						</Box>
					))
				) : (
					<Stack
						alignItems="center"
						justifyContent="center"
						style={{
							height: "150px",
						}}
					>
						<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
							No notes found
						</Typography>
					</Stack>
				)}

				<Box ref={lastRowRef}>
					{!isLoadingNoteList && isFetching && (
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="center"
							spacing={1}
							py={2}
						>
							<CircularProgress size={18} />

							<Typography fontSize={12}>
								Loading More Data
							</Typography>
						</Stack>
					)}
				</Box>
			</Box>
		</React.Fragment>
	);
}
