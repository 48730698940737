import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	getRolesDetailsKey,
	getRolesListKey,
	getRolesTableDataKey,
	getRolesUserListKey,
	getRoleTableDataWithSortKey,
} from "../../../../utils/queryKeys/userManagement/roles";
import {
	createRole,
	getRoleDetails,
	deleteRole,
	getRolesList,
	getRolesTableData,
	getRolesUserList,
	linkUserToRole,
	updateRole,
	updateRoleDetails,
} from "../../../../api/userManagement/roles/rolesApi";

const useRoleTableData = (fieldToSort, enabled) => {
	return useQuery(
		getRoleTableDataWithSortKey(fieldToSort),
		() => getRolesTableData(fieldToSort),
		{
			select: (data) => data.roles,
			enabled: enabled,
		}
	);
};

const useRolesList = () => {
	return useQuery(getRolesListKey(), () => getRolesList(), {
		select: (rolesList) => rolesList?.options,
	});
};

const useRoleDetails = (id, enabled) => {
	return useQuery(getRolesDetailsKey(id), () => getRoleDetails(id), {
		select: (data) => data?.data,
		enabled: enabled,
	});
};

const useCreateRole = () => {
	let invalidateRoleList = useInvalidateRoleList();
	return useMutation(createRole, {
		onSuccess: () => {
			invalidateRoleList();
		},
	});
};

const useInvalidateRoleDetails = (id) => {
	let queryClient = useQueryClient();
	let roleDetailsKey = getRolesDetailsKey(id);
	return () => {
		queryClient.invalidateQueries(roleDetailsKey);
	};
};

const useInvalidateRoleList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getRolesTableDataKey());
	};
};

const useUpdateRoleMutation = () => {
	let invalidateRoleList = useInvalidateRoleList();
	return useMutation(updateRole, {
		onSuccess: () => {
			invalidateRoleList();
		},
	});
};

const useLinkUserMutation = () => {
	let invalidateRoleList = useInvalidateRoleList();
	return useMutation(linkUserToRole, {
		onSuccess: () => {
			invalidateRoleList();
		},
	});
};

const useUpdateRoleDetails = (id) => {
	let invalidateRoleDetails = useInvalidateRoleDetails(id);
	let invalidateRoleList = useInvalidateRoleList();
	return useMutation(updateRoleDetails, {
		onSuccess: () => {
			invalidateRoleDetails(id);
			invalidateRoleList();
		},
	});
};

const useDeleteRole = () => {
	let invalidateRoleList = useInvalidateRoleList();
	return useMutation(deleteRole, {
		onSuccess: () => {
			invalidateRoleList();
		},
	});
};

const useRolesUserList = (id) => {
	let rolesUserListKey = getRolesUserListKey(id);
	return useQuery(rolesUserListKey, () => getRolesUserList(id), {
		select: (data) => data?.users,
		enabled: !!id,
	});
};

export {
	useRolesList,
	useUpdateRoleMutation,
	useLinkUserMutation,
	useRolesUserList,
	useRoleTableData,
	useCreateRole,
	useRoleDetails,
	useUpdateRoleDetails,
	useDeleteRole,
};
