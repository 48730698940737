import { getDropdownKeyByFieldName } from "../commonQueryKeys";
import { getRootKey } from "../rootKey";

const emailKey = "email";

export const getEmailKey = () => {
	return [...getRootKey(), emailKey]; // Return the email query key
};

// query keys
export const connectedEmailKey = [...getEmailKey(), "connectedEmailData"];

export const emailSettingsDataKey = [...getEmailKey(), "emailSettingsData"];

// query key methods

export const getMailListKey = (mailListName) => {
	return [...getEmailKey(), mailListName];
};

export const getMailListWithFilterKey = (mailListName, filter) => {
	return [...getMailListKey(mailListName), filter];
};

export const getMailFilterDataKey = (mailListName) => {
	return [...getEmailKey(), mailListName, "filtersData"];
};

export const getMailDataKey = (mailId, isTrash) => {
	if (isTrash) {
		return [...getEmailKey(), mailId, isTrash];
	}
	return [...getEmailKey(), mailId];
};

export const getSingleConversationDataKey = (conversationId) => {
	return [...getEmailKey(), String(conversationId), "singleConversationData"];
};

export const getFromAddressDropdownKey = () => {
	return [...getDropdownKeyByFieldName("fromAddressList")];
};

export const getMailRecipientsListKey = () => {
	return [...getDropdownKeyByFieldName("mailRecipientsList")];
};

export const getMetricsKey = (metricId) => {
	return [...getEmailKey(), String(metricId), "metrices"];
};

export const getMetricsInsightsKey = (metricId, metricType) => {
	return [...getEmailKey(), String(metricId), metricType];
};
