import React from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useNoteList } from "../../../../hooks/services/summary/notes/notes";
import ShortNoteItem from "../ShortNoteItem";
import { useLastRowRef } from "../../../../hooks/common/InfiniteScroll";

export default function ShortNoteList(props) {
	const { source, entityId, handleEditNote } = props;
	const {
		data: noteList,
		isLoading: isLoadingNoteList,
		hasNextPage,
		isFetching,
		fetchNextPage,
	} = useNoteList(source, entityId);

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	return (
		<React.Fragment>
			<Box>
				{isLoadingNoteList ? (
					<Stack alignItems="center" p={5}>
						<CircularProgress size="30px" />
					</Stack>
				) : (
					<Box
						mx={-1}
						mt={0.5}
						sx={{
							maxBlockSize: "300px",
							overflowY: "auto",
						}}
					>
						{noteList?.length > 0 ? (
							noteList?.map((noteData) => (
								<ShortNoteItem
									key={noteData?.id}
									noteData={noteData}
									entityId={entityId}
									sourceName={source}
									handleEditNote={handleEditNote}
								/>
							))
						) : (
							<Stack
								alignItems="center"
								justifyContent="center"
								style={{
									height: "60px",
								}}
							>
								<Typography
									fontSize={13}
									color="rgba(0, 0, 0, 0.6)"
								>
									No notes found
								</Typography>
							</Stack>
						)}

						<Box ref={lastRowRef}>
							{!isLoadingNoteList && isFetching && (
								<Stack
									direction="row"
									alignItems="center"
									justifyContent="center"
									spacing={1}
									py={2}
								>
									<CircularProgress size={18} />

									<Typography fontSize={12}>
										Loading More Data
									</Typography>
								</Stack>
							)}
						</Box>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}
