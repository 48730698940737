import React, { useState } from "react";
import { default as AddIcon } from "../../../../assets/icons/add";
import { default as CallIcon } from "../../../../assets/icons/call";
import { default as MoreIcon } from "../../../../assets/icons/more";
import { default as MailIcon } from "../../../../assets/icons/mail";
import {
	Box,
	Button,
	IconButton,
	Stack,
	useTheme,
	MenuItem,
	Typography,
} from "@mui/material";
import { default as EditIcon } from "../../../../assets/icons/edit";
import { default as DeleteIcon } from "../../../../assets/icons/delete";
import { default as CopyIcon } from "../../../../assets/icons/copy";
import { default as ExportIcon } from "../../../../assets/icons/export";
import Menu from "../../../Elements/Menu";
import DeleteDialog from "../../../Elements/DeleteDialog";
import { useMutation } from "@tanstack/react-query";
import { deleteContact } from "../../../../api/contacts/contactsApi";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import CloneContact from "../CloneContact";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import { twozoStyles } from "../../../../styles/twozo";
import { modules } from "../../../../utils/common/ModulesName";
import EmailComposerDrawer from "../../../Mail/EmailComposer/EmailComposerDrawer";
import { useAuth } from "../../../../hooks/auth";
import Can from "../../../Auth/Can";
import { PERMISSIONS } from "../../../../utils/Auth";
import { useModuleName } from "../../../../hooks/modules";

export default function SummaryMenu(props) {
	const { contactId, toggleEditFormDialog, onAddDealClick } = props;
	const theme = useTheme();
	const navigate = useNavigate();
	const classes = twozoStyles();
	const [contactSummaryMenuElement, setContactSummaryMenuElement] =
		useState(null);
	const isContactSummaryMenuOpened = Boolean(contactSummaryMenuElement);
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
	const successNotificationTitle = "Success";
	const [isContactCloneDialogOpened, setIsContactCloneDialogOpened] =
		useState(false);
	const [isEmailComposerOpened, setIsEmailComposerOpened] = useState(false);

	const { isUserAllowedFor } = useAuth();

	const { getModuleName } = useModuleName();

	const openContactSummaryMenu = (event) => {
		setContactSummaryMenuElement(event.currentTarget);
	};

	const closeContactSummaryMenu = () => {
		setContactSummaryMenuElement(null);
	};

	const handleEditContact = () => {
		toggleEditFormDialog();
		setContactSummaryMenuElement(null);
	};

	const openDeleteDialog = () => {
		setIsDeleteDialogOpened(true);
		setContactSummaryMenuElement(null);
	};

	const toggleContactCloneDialog = () => {
		setIsContactCloneDialogOpened(
			(isContactCloneDialogOpened) => !isContactCloneDialogOpened
		);
	};

	const handleCloneContact = () => {
		toggleContactCloneDialog();
		closeContactSummaryMenu();
	};

	const contactMenu = [
		{
			id: "edit",
			name: "Edit",
			icon: EditIcon,
			action: handleEditContact,
			hasPermissionToAccess: isUserAllowedFor(PERMISSIONS.contact.edit),
		},
		{
			id: "clone",
			name: "Clone",
			icon: CopyIcon,
			action: handleCloneContact,
			hasPermissionToAccess: isUserAllowedFor(
				PERMISSIONS.contact.cloneRecords
			),
		},
		{
			id: "delete",
			name: "Delete",
			icon: DeleteIcon,
			action: openDeleteDialog,
			hasPermissionToAccess: isUserAllowedFor(PERMISSIONS.contact.delete),
		},
		{
			id: "export",
			name: "Export",
			icon: ExportIcon,
			hasPermissionToAccess: isUserAllowedFor(
				PERMISSIONS.contact.exportRecords
			),
		},
	];

	const closeDeleteDialog = () => {
		setIsDeleteDialogOpened(false);
	};

	const handleDeleteContact = () => {
		deleteMutation.mutate(contactId);
	};

	const deleteMutation = useMutation(
		async (contactId) => deleteContact(contactId),
		{
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					title: successNotificationTitle,
					message: notificationMessage.contactDeleted,
				});
				navigate("/contacts");
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		}
	);

	const openEmailComposer = () => {
		setIsEmailComposerOpened(true);
	};

	const closeEmailComposer = () => {
		setIsEmailComposerOpened(false);
	};

	const getContactMenuOptions = () => {
		return contactMenu.filter(
			(contactMenuOption) => contactMenuOption.hasPermissionToAccess
		);
	};

	let moduleName = getModuleName(modules.CONTACT).toLowerCase();

	return (
		<React.Fragment>
			<DeleteDialog
				title={`Are you sure you want to delete the ${moduleName}?`}
				subtitle={`It will delete the ${moduleName} and the data associated with it. You can retrieve it from the Recycle Bin. It remains there for 90 days.`}
				open={isDeleteDialogOpened}
				onCancel={closeDeleteDialog}
				onDelete={handleDeleteContact}
				disableDeleteButton={deleteMutation.isLoading}
			></DeleteDialog>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isContactCloneDialogOpened}
				onOpen={toggleContactCloneDialog}
				onClose={toggleContactCloneDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<CloneContact
						onClose={toggleContactCloneDialog}
						contactId={contactId}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Menu
				minWidth="200px"
				anchorEl={contactSummaryMenuElement}
				open={isContactSummaryMenuOpened}
				onClose={closeContactSummaryMenu}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				style={{
					marginTop: "8px",
				}}
			>
				{getContactMenuOptions().map((menuItem) => (
					<MenuItem
						key={menuItem.id}
						style={{ height: "40px" }}
						onClick={() => menuItem.action()}
					>
						<Stack
							direction="row"
							alignItems="center"
							spacing={1.5}
						>
							{menuItem.icon(
								"20",
								"20",
								theme.palette.primary.main
							)}
							<Typography
								fontSize={13}
								fontWeight={500}
								color={theme.palette.secondary.contrastText}
							>
								{menuItem.name}
							</Typography>
						</Stack>
					</MenuItem>
				))}
			</Menu>

			{isEmailComposerOpened ? (
				<EmailComposerDrawer
					onClose={closeEmailComposer}
					composerValues={{ toAddress: [contactId] }}
					sourceName={modules.CONTACT}
					entityId={contactId}
				/>
			) : null}

			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={1}
				height="100%"
			>
				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
					spacing={2}
					height="100%"
				>
					<Button
						variant="contained"
						startIcon={MailIcon(
							20,
							20,
							theme.palette.secondary.contrastText
						)}
						color="secondary"
						disableElevation
						onClick={openEmailComposer}
					>
						Send Email
					</Button>

					<Button
						variant="contained"
						startIcon={CallIcon(
							20,
							20,
							theme.palette.secondary.contrastText
						)}
						color="secondary"
						disableElevation
					>
						Call
					</Button>

					<Can permission={PERMISSIONS.deal.create}>
						<Button
							variant="contained"
							startIcon={AddIcon(20, 20, "#fff")}
							onClick={onAddDealClick}
							disableElevation
						>
							Deal
						</Button>
					</Can>
				</Stack>

				{getContactMenuOptions().length > 0 ? (
					<IconButton size="small" onClick={openContactSummaryMenu}>
						{MoreIcon(20, 20, theme.palette.primary.main)}
					</IconButton>
				) : null}
			</Stack>
		</React.Fragment>
	);
}
