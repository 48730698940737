import React from "react";
import { TableCellText } from "../../../../styles/twozo";
import CanNot from "../../../Auth/CanNot";
import { PERMISSIONS } from "../../../../utils/Auth";
import Can from "../../../Auth/Can";
import { Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Contact(props) {
	const { data } = props;
	const theme = useTheme();
	const navigate = useNavigate();

	if (!data) {
		return null;
	}

	const navigateToContact = () => {
		navigate(`/contacts/${data?.id?.toString()}`);
	};

	return (
		<React.Fragment>
			<Can permission={PERMISSIONS.contact.view}>
				<Box onClick={navigateToContact}>
					<TableCellText color={theme.palette.secondary.contrastText}>
						{data?.firstName} {data?.lastName}
					</TableCellText>
				</Box>
			</Can>

			<CanNot permission={PERMISSIONS.contact.view}>
				<TableCellText>-</TableCellText>
			</CanNot>
		</React.Fragment>
	);
}
