import React, { useMemo } from "react";
import { modules } from "../../../../../utils/common/ModulesName";
import EmailComposerDrawer from "../../../EmailComposer/EmailComposerDrawer";
import {
	useDraftMailDataByConversationId,
	useDraftMailDataByThreadId,
} from "../../../../../hooks/email";

export default function DraftComposerDrawer(props) {
	const { onClose, threadId, conversationId, sourceName, entityId } = props;

	const { draftMailThreadData, isFetchingThreadData } =
		useDraftMailDataByThreadId(threadId);
	const { draftMailConversationData, isFetchingConversationData } =
		useDraftMailDataByConversationId(conversationId);

	const defaultComposerData = useMemo(() => {
		if (draftMailConversationData) {
			return draftMailConversationData;
		}
		if (draftMailThreadData) {
			return draftMailThreadData;
		}
	}, [draftMailConversationData, draftMailThreadData]);

	return (
		<React.Fragment>
			<EmailComposerDrawer
				onClose={onClose}
				composerValues={defaultComposerData}
				sourceName={sourceName}
				entityId={entityId}
				emailContext={{
					conversationId: conversationId,
					threadId: threadId,
				}}
				linkConversationToContact={sourceName === modules.CONTACT}
				linkConversationToDeal={sourceName === modules.DEAL}
				hasDraftMail={true}
				isloadingComposerValues={
					isFetchingConversationData || isFetchingThreadData
				}
			/>
		</React.Fragment>
	);
}
