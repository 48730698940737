import { apiClient } from "..";
import { getEntityUrl } from "../apiConfig";
import { assertError } from "../apiHelper";

export const getConversationsData = async (
	sourceName,
	entityId,
	pageParams
) => {
	let requestData = getConversationsRequest(sourceName, entityId, pageParams);
	return await postConversationManagementApi(requestData);
};

export const unlinkConversation = async ({ threadId, entityId, source }) => {
	let requestData = getUnlinkConversationRequest(threadId, entityId, source);
	return await postConversationLinkManagementApi(requestData);
};

const getConversationsRequest = (sourceName, entityId, pageParams) => {
	return {
		type: "getEntityConversations",
		data: {
			source: sourceName,
			entityId: entityId,
			...pageParams,
		},
	};
};

const getUnlinkConversationRequest = (threadId, entityId, source) => {
	return {
		type: "unlinkSource",
		data: {
			threadId: threadId,
			entityId: entityId,
			source: source,
		},
	};
};

const postConversationManagementApi = async (requestData) => {
	let conversationsManagementUrl = getConversationsManagementUrl();
	let response = await apiClient.post(
		conversationsManagementUrl,
		requestData
	);
	assertError(response);
	return response.data.data;
};

const postConversationLinkManagementApi = async (requestData) => {
	let conversationsLinkManagementUrl = getConversationsLinkManagementUrl();
	let response = await apiClient.post(
		conversationsLinkManagementUrl,
		requestData
	);
	assertError(response);
	return response.data.data;
};

const getConversationsLinkManagementUrl = () => {
	return getEntityUrl() + "/conversation/link/mgmt";
};

const getConversationsManagementUrl = () => {
	return getEntityUrl() + "/conversations/mgmt";
};
