import {
	Box,
	Button,
	CircularProgress,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { default as CloseIcon } from "../../../assets/icons/close";
import { default as SettingsIcon } from "../../../assets/icons/settings";
import { default as NotificationIcon } from "../../../assets/icons/notification";
import { getIconByName } from "../../../utils";
import { twozoStyles } from "../../../styles/twozo";
import {
	useMarkAllAsReadMutation,
	useMarkAsReadMutation,
	useNotificationAlertState,
	useNotificationsData,
	useUpdateNotificationAlertStateMutation,
} from "../../../hooks/services/notification/appNotification";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import { useNavigate } from "react-router-dom";
import SingleConversationViewer from "../../Mail/SingleConversationView";
import CustomSwipeableDrawer from "../CustomSwipeableDrawer";
import { notificationTypes } from "../../../utils/notificationUtils";
import ActivitySummary from "../../Activities/ActivitySummary";
import { getDateDifferenceWithHourAndMinute } from "../../../utils/DateUtils";
import NotificationMessage from "./NotificationMessage";

export default function Notification(props) {
	const { onClose } = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const navigate = useNavigate();

	const [isMailViewerOpened, setIsMailViewerOpened] = useState(false);
	const [openedConversationId, setOpenedConversationId] = useState("");
	const [isActivitySummaryOpened, setIsActivitySummaryOpened] =
		useState(false);
	const [openedActivityId, setOpenedActivityId] = useState(null);

	const { data: notifications, isLoading: isNotificationsLoading } =
		useNotificationsData();
	const { data: notificationAlertState } = useNotificationAlertState();
	const alertsButtonText =
		notificationAlertState === "on" ? "Turn on alerts" : "Turn off alerts";
	let notificationContentColor = "#000";
	let isNotificationActionEnabled = true;

	const updateNotificationStateMutation =
		useUpdateNotificationAlertStateMutation();
	const markAsReadMutation = useMarkAsReadMutation();
	const markAllAsReadMutation = useMarkAllAsReadMutation();

	const handleNotificationAlert = () => {
		updateNotificationStateMutation.mutate(notificationAlertState, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message:
						notificationAlertState === "off"
							? notificationMessage.turnOffNotificationMessage
							: notificationMessage.turnOnNotificationMessage,
				});
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const handleMarkAllAsRead = (_) => {
		markAllAsReadMutation.mutate(_, {
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const handleUpdateMarkAsRead = (notificationId) => {
		markAsReadMutation.mutate(notificationId);
	};

	const navigateToNotificationSettings = () => {
		navigate("/settings/email?type=notifications");
		onClose();
	};

	const renderEmptyView = () => {
		return (
			<React.Fragment>
				<Stack
					alignItems="center"
					justifyContent="center"
					height="85vh"
					spacing={2}
				>
					{NotificationIcon(30, 30, "#626262")}

					<Typography fontSize={14} color="#626262">
						You don’t have any notifications.
					</Typography>
				</Stack>
			</React.Fragment>
		);
	};

	const openMailViewer = (notificationData) => {
		setIsMailViewerOpened(true);
		setOpenedConversationId(notificationData.data.id);
		handleUpdateMarkAsRead(notificationData.id);
	};

	const closeMailViewer = () => {
		setIsMailViewerOpened(false);
		setOpenedConversationId("");
	};

	const openActivitySummary = (notificationData) => {
		setOpenedActivityId(notificationData.data.id);
		setIsActivitySummaryOpened(true);
		handleUpdateMarkAsRead(notificationData.id);
	};

	const closeActivitySummary = () => {
		setIsActivitySummaryOpened(false);
		setOpenedActivityId("");
	};

	const handleNavigateToCalendarSync = (notificationData) => {
		handleUpdateMarkAsRead(notificationData?.id);
		navigate("/settings/email?type=calendar_sync");
		onClose();
	};

	const handleNavigateToRoles = (notificationData) => {
		handleUpdateMarkAsRead(notificationData?.id);
		navigate("/settings/user-management?type=roles");
		onClose();
	};

	const handleNavigateToTeams = (notificationData) => {
		handleUpdateMarkAsRead(notificationData?.id);
		navigate("/settings/user-management?type=teams");
		onClose();
	};

	const handleNavigateToContactList = (notificationData) => {
		handleUpdateMarkAsRead(notificationData?.id);
		navigate("/contacts");
		onClose();
	};

	const handleNavigateToCompanyList = (notificationData) => {
		handleUpdateMarkAsRead(notificationData?.id);
		navigate("/companies");
		onClose();
	};

	const handleNavigateToDealList = (notificationData) => {
		handleUpdateMarkAsRead(notificationData?.id);
		navigate("/deals?view=list");
		onClose();
	};

	const handleNavigateToProductList = (notificationData) => {
		handleUpdateMarkAsRead(notificationData?.id);
		navigate("/products");
		onClose();
	};

	const handleNotificationClick = (notificationData) => {
		switch (notificationData?.type) {
			case notificationTypes.emailReceived: {
				return openMailViewer(notificationData);
			}
			case notificationTypes.emailReplied: {
				return openMailViewer(notificationData);
			}
			case notificationTypes.emailClicked: {
				return openMailViewer(notificationData);
			}
			case notificationTypes.emailOpened: {
				return openMailViewer(notificationData);
			}
			case notificationTypes.activityReminder: {
				return openActivitySummary(notificationData);
			}
			case notificationTypes.activityAssigned: {
				return openActivitySummary(notificationData);
			}
			case notificationTypes.calendarSynced: {
				return handleNavigateToCalendarSync(notificationData);
			}
			case notificationTypes.roleChanged: {
				return handleNavigateToRoles(notificationData);
			}
			case notificationTypes.addedToTeam: {
				return handleNavigateToTeams(notificationData);
			}
			case notificationTypes.contactAssigned: {
				return handleNavigateToContactList(notificationData);
			}
			case notificationTypes.companyAssigned: {
				return handleNavigateToCompanyList(notificationData);
			}
			case notificationTypes.dealAssigned: {
				return handleNavigateToDealList(notificationData);
			}
			case notificationTypes.productAssigned: {
				return handleNavigateToProductList(notificationData);
			}
			case notificationTypes.productEnabled: {
				return handleNavigateToProductList(notificationData);
			}
			default:
				return handleUpdateMarkAsRead(notificationData?.id);
		}
	};

	return (
		<React.Fragment>
			{/*Mail Viewer*/}
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isMailViewerOpened}
				onOpen={openMailViewer}
				onClose={closeMailViewer}
				disableBackdropClick={true}
				hideBackdrop
			>
				<Box className={classes.addDialogContainer}>
					<SingleConversationViewer
						onClose={closeMailViewer}
						conversationId={openedConversationId}
					/>
				</Box>
			</CustomSwipeableDrawer>

			{/*Activity Summary*/}
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: {
						backgroundColor: "transparent",
					},
				}}
				open={isActivitySummaryOpened}
				onOpen={openActivitySummary}
				onClose={closeActivitySummary}
				disableBackdropClick={true}
				hideBackdrop
			>
				<Box className={classes.addDialogContainer}>
					<ActivitySummary
						onClose={closeActivitySummary}
						activityId={openedActivityId}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box px={2} py={1}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Typography fontSize={16} fontWeight={600}>
							Notifications
						</Typography>

						<Stack direction="row" spacing={1} alignItems="center">
							<Button
								variant="outlined"
								style={{
									borderColor: theme.palette.secondary.main,
									textTransform: "none",
								}}
								onClick={handleMarkAllAsRead}
							>
								Mark all as read
							</Button>

							<Button
								variant="outlined"
								style={{
									borderColor: theme.palette.secondary.main,
									textTransform: "none",
								}}
								onClick={handleNotificationAlert}
							>
								{alertsButtonText ? alertsButtonText : ""}
							</Button>

							<Box display="flex">
								<IconButton
									onClick={navigateToNotificationSettings}
								>
									{SettingsIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</IconButton>
							</Box>

							<Divider
								orientation="vertical"
								sx={{ height: "20px" }}
							/>

							<Box display="flex">
								<IconButton size="small" onClick={onClose}>
									{CloseIcon(
										24,
										24,
										theme.palette.primary.main
									)}
								</IconButton>
							</Box>
						</Stack>
					</Stack>
				</Box>

				<Divider />

				<Box
					style={{
						flex: "1 1 auto",
						minHeight: 0,
						overflowY: "auto",
					}}
				>
					{isNotificationsLoading ? (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="85vh"
							spacing={2}
						>
							<CircularProgress size="30px" />
						</Stack>
					) : notifications?.length > 0 ? (
						notifications?.map((notification, index) => (
							<Box key={index}>
								<Box
									p={2}
									style={{
										backgroundColor: notification.isRead
											? "#fff"
											: "#F4F5F5",
										cursor: "pointer",
									}}
									onClick={() =>
										handleNotificationClick(notification)
									}
								>
									<Stack
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										width="100%"
									>
										<Stack
											direction="row"
											spacing={3}
											width="80%"
										>
											<Box display="flex">
												<IconButton>
													{getIconByName(
														notification.icon.name
													)(
														24,
														24,
														"rgba(0, 0, 0, 0.6)"
													)}
												</IconButton>
											</Box>

											<Box
												onClick={() =>
													handleNotificationClick(
														notification
													)
												}
											>
												<NotificationMessage
													notificationData={
														notification
													}
													onClose={onClose}
													notificationContentColor={
														notificationContentColor
													}
													isNotificationActionEnabled={
														isNotificationActionEnabled
													}
												/>
											</Box>
										</Stack>

										<Typography
											style={{
												width: "20%",
												textAlign: "right",
											}}
											fontSize={13}
										>
											{getDateDifferenceWithHourAndMinute(
												notification.timestamp
											)}
										</Typography>
									</Stack>
								</Box>

								<Divider />
							</Box>
						))
					) : (
						renderEmptyView()
					)}
				</Box>
			</Box>
		</React.Fragment>
	);
}
