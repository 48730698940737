import DateTimeRangePicker from "../../../../Elements/DateTimeRangePicker";
import moment from "moment-timezone";
import dayjs from "dayjs";
import { useRef } from "react";

export default function ActivityDateAndTime({
	startTimeValue,
	endTimeValue,
	onStartTimeChange,
	onEndTimeChange,
}) {
	const isEndDateManuallySetRef = useRef(false);

	const manuallySetEndTime = ({ from, to }, preFromDate) => {
		let startDate = new Date(from);
		let endDate = new Date(to);
		let preStartDate = new Date(preFromDate);

		let startDateInDateFormat = moment(startDate);
		let preDateInFormat = moment(preStartDate);

		let dateDiff = startDateInDateFormat.diff(preDateInFormat, "days");

		let adjustedEndDate = new Date(endDate);
		adjustedEndDate.setDate(
			endDate.getDate() + (dateDiff !== 0 ? dateDiff : 1)
		);
		let unixTimestamp = adjustedEndDate.getTime();
		onEndTimeChange(unixTimestamp);
	};

	const handleChangeDateTimeRangeValue = (newDateRangeValue) => {
		if (startTimeValue !== newDateRangeValue.from) {
			onStartTimeChange(newDateRangeValue.from);

			if (isEndDateManuallySetRef.current) {
				manuallySetEndTime(newDateRangeValue, startTimeValue);
			} else {
				onEndTimeChange(newDateRangeValue.from);
			}
		} else {
			onEndTimeChange(newDateRangeValue.to);
			isEndDateManuallySetRef.current = true;
		}
	};

	const getMaxToDateValue = (date) => {
		// the activity duration limit one month
		let startDate = new Date(date);
		let oneMonthLater = new Date(
			startDate.getFullYear(),
			startDate.getMonth() + 1,
			startDate.getDate()
		);

		return oneMonthLater ? dayjs(oneMonthLater.getTime()) : null;
	};

	return (
		<DateTimeRangePicker
			onChange={handleChangeDateTimeRangeValue}
			value={{
				from: startTimeValue,
				to: endTimeValue,
			}}
			maxDateValue={getMaxToDateValue(startTimeValue)}
		/>
	);
}
