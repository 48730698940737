import {
	useInfiniteQuery,
	useMutation,
	useQueryClient,
} from "@tanstack/react-query";
import { getConversationListKey } from "../../../utils/queryKeys/conversations";
import { getConversationsData } from "../../../api/conversations";
import { deleteSingleMail } from "../../../api/email";
import { conversationPageSize } from "../../../utils/queryConstants/summary/conversation";

const conversationSelect = (conversationData) => {
	return conversationData.pages.flatMap((page) => page?.conversations);
};

const useConversationList = (sourceName, entityId) => {
	return useInfiniteQuery({
		queryKey: getConversationListKey(sourceName, entityId),
		queryFn: ({ pageParam }) => {
			return getConversationsData(sourceName, entityId, {
				start: pageParam
					? (pageParam - 1) * conversationPageSize + 1
					: 1,
				limit: conversationPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: conversationSelect,
	});
};

const useInvalidateConversationList = (sourceName, entityId) => {
	let queryClient = useQueryClient();
	const conversationListKey = getConversationListKey(sourceName, entityId);
	return () => {
		queryClient.invalidateQueries(conversationListKey);
	};
};

const useDeleteMailForConversation = (sourceName, entityId) => {
	const invalidateConversationList = useInvalidateConversationList(
		sourceName,
		entityId
	);
	return useMutation(deleteSingleMail, {
		onSuccess: () => {
			invalidateConversationList();
		},
	});
};

export {
	useConversationList,
	useInvalidateConversationList,
	useDeleteMailForConversation,
};
